import { Injectable } from '@angular/core';
import { Basket } from '../models/basket';
import { BasketProduct } from '../models/basketProduct';
import { Product } from '../models/product';
import { Globals } from '../globals';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BasketService {

  constructor(private globals: Globals) {  }

  getBasketItem(item: Product) {
    if (this.globals.basket) {
      var basket = this.globals.basket;   
      var basketProd = null;

      basket.basketProduct.forEach(function (bp, key) {

        if (item.productID == bp.product.productID) {
            
              basketProd = bp;
           return;     
        }

      }); 

      return basketProd;
    }
  }

  deleteBasketItem(item, orderType?) {
    if (this.globals.basket) {
      var basket = this.globals.basket;   
      basket.basketProduct.forEach(function (bp, key) {

        if (item.productID == bp.product.productID) {
           basket.basketProduct.splice(key, 1);      
        }

      }); 

      this.globals.basket = basket;
      this.updateBasket(orderType);
    }
  }

  updateBasket(orderType?) {

    if (this.globals.basket) {
      var basket = this.globals.basket;
      var productTotal = 0;
      var subTotal = 0;
      var total = 0;
      var deliveryCosts = 0;
      var self = this;

      basket.basketProduct.forEach(function (bp, key) {

        if (self.globals.country === "cur") {
          basket.currency = "ANG";
          if (bp.product.availableCur == 1 || bp.product.deal == 1) {       
            bp.total = (bp.amount * bp.product.dealPriceCur);
          } else {
            bp.total = (bp.amount * bp.product.basePrice);
          }

          if(bp.product.publishCur != '1' || bp.product.deal == 1 && bp.product.availableCur == 0){
            bp.total = 0;
         
          }

          subTotal += bp.total;
          productTotal += bp.amount;

          if(subTotal < 200 && orderType === 'delivery'){        
            deliveryCosts = 15;
          }else{
            deliveryCosts = 0;
          }        
        }
        else if (self.globals.country === "bon") {
          basket.currency = "$";
          if (bp.product.availableBon == 1 || bp.product.deal == 1) {
            bp.total = (bp.amount * bp.product.dealPriceBon);
          } else {
            bp.total = (bp.amount * bp.product.basePriceBon);
          }

          if(bp.product.publishBon != '1' || bp.product.deal == 1 && bp.product.availableBon == 0){
            bp.total = 0;
         
          }

          subTotal += bp.total;
          productTotal += bp.amount;

          if(subTotal < 100 && orderType === 'delivery'){
            deliveryCosts = 10;
          }else{
            deliveryCosts = 0;
          }

         
        }
      });

      
      total = subTotal + deliveryCosts;
      basket.subTotal = subTotal;
      basket.total = total;
      basket.productTotal = productTotal;
      basket.subTotalDecimal = subTotal.toFixed(2);
      basket.totalDecimal = total.toFixed(2);
      basket.deliveryCosts = deliveryCosts;
      basket.deliveryCostsDecimal = deliveryCosts.toFixed(2);
      this.globals.basket = basket;     
    }
  }


  addProduct(product: Product, amount) {
    if (amount <= 0) {
      this.deleteBasketItem(product);
      return false;
    }
    var basket = null as null | Basket;
    var basketProduct = null as null | BasketProduct;
    var basketExists = false;

    if (this.globals.basket) {
      basket = this.globals.basket;
    }
    else {
      basket = new Basket;
    }

    if (basket.basketProduct.length) {

      var x = this;
      var productTotal = 0;
      var subTotal = 0;

      basket.basketProduct.forEach(function (bp, key) {

        if (bp.product.productID === product.productID) {
          bp.amount += amount;
          // productTotal = (bp.amount * bp.product.basePrice);
          // bp.total = productTotal;
          basketExists = true;
        }

        // subTotal += (bp.amount * bp.product.basePrice);

      });

      // basket.subTotal = subTotal;

    }

    if (!basketExists) {
      basketProduct = new BasketProduct;
      basketProduct.amount = amount;
      basketProduct.product = product;
      // basketProduct.total = product.basePrice * 1;
      // basket.subTotal += product.basePrice * 1;
      basket.basketProduct.push(basketProduct);
    }

    // basket.subTotalDecimal = basket.subTotal.toFixed(2);
    this.globals.basket = basket;
    this.updateBasket();
  }

  getSubTotal(): string {
    return this.globals.basket && this.globals.basket.subTotalDecimal ? this.globals.basket.subTotalDecimal : '0.00';
  }

  getDeliveryCosts(): string {
    return this.globals.basket && this.globals.basket.deliveryCostsDecimal ? this.globals.basket.deliveryCostsDecimal : '0.00';
  }

  getTotal(): string {
    return this.globals.basket && this.globals.basket.totalDecimal ? this.globals.basket.totalDecimal : '0.00';
  }

  
  getProductTotal(): number {
    return this.globals.basket && this.globals.basket.productTotal ? this.globals.basket.productTotal : 0;
  }
 

  getBasket(): Basket {
    return this.globals.basket ? this.globals.basket : null;
  }

  getCountry(): string {
    if (this.globals.country === "cur") {
      return "Curaçao";
    }
    else if (this.globals.country === "bon") {
      return "Bonaire";
    }
    else {

    }

  }

  getCurrency(): string {
    if (this.globals.country === "cur") {
      return "ANG";
    }
    else if (this.globals.country === "bon") {
      return "$";
    }
    else {
      //throw error
    }
    //return this.globals.basket ? this.globals.basket.currency : "ANG";
  }

  getBasketProducts(): BasketProduct[] {
    return this.globals.basket && this.globals.basket.basketProduct ? this.globals.basket.basketProduct : [];
  }
}


