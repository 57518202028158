import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filters } from '../models/filters';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getByNameID(categoryNameID:number): Observable<Filters> {
    return this.http.post<Filters>(`${this.globals.serverlink}getFiltersByCategoryNameID.php`, {categoryNameID});
  }
}
