<div class="wrapper main overflow">
    <div class="category-title">Home Item</div>
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Publish Cur</th>
                <th scope="col">Publish Bon</th>
                <th scope="col">Active</th>
                <th scope="col">&nbsp;</th>
                <th style="text-align: right;" scope="col"><i style="cursor: pointer;" class="fas fa-plus-circle" routerLink="/addhomeitem"></i></th>
            </tr> 
        </thead>
        <tbody>
            <tr  *ngFor="let hi of homeItems ; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{hi.name}}</td>
                <td>{{hi.publishCur === '1' ? 'Yes'  : 'No'}}</td>
                <td>{{hi.publishBon === '1' ? 'Yes': 'No'}}</td>
                <td>{{hi.active === '1' ? "Yes" : 'No'}}</td>
                <td style="text-align: right;">
                    <button routerLink="/homeitems/{{hi.homeItemID}}" type="button" class="btn btn-dark btn-sm">Edit</button>
                </td>
                <td style="text-align: right;">
                    <button routerLink="/homeitemproducts/{{hi.homeItemID}}" type="button" class="btn btn-dark btn-sm">Items</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div style="height: 200px;">
    &nbsp;
</div>