import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-full-overlay',
  templateUrl: './full-overlay.component.html',
  styleUrls: ['./full-overlay.component.scss']
})
export class FullOverlayComponent implements OnInit {

  constructor(
    public globals: Globals
  ) { }

  ngOnInit(): void {
  }

  closeBackDrop(){
    this.globals._navState = false;
    this.globals._backDrop = false;
    this.globals._sideFilter = false;
  }

}
