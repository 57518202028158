import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../models/brand';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ButtonService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }
 

  getButtonNames(): any {
    return this.http.post(`${this.globals.serverlink}getButtonNames.php`, {});
  }

  getButtonActions(): any {
    return this.http.post(`${this.globals.serverlink}getButtonActions.php`, {});
  }
}
