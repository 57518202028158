import { Brand } from './brand';
import { Country } from './country';
import { Color } from './color';
import { Category } from './category';
import { Region } from './region';
import { Intensity } from './intensity';
import { Tasteprofile } from './tasteprofile';
import { Grape } from './grape';
import { BasketService } from '../services/basket.service';


export class Filters {
  category: string;
  brands: Brand[];
  countries: Country[];
  colors: Color[];
  subcategories: Category[];
  regions: Region[];
  intensities: Intensity[];
  tasteprofiles: Tasteprofile[];
  prices: any[];
  currentcurrency: string;
  pagingdata: any;
  lastroute: string;
  order: string;
  topProducts: any[];
  grapes: Grape[];
  variants: any[];
  activeFilters: any[];
}
