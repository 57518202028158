import { Component, OnInit, ɵConsole } from '@angular/core';
import { Product } from '../../models/product';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BasketProduct } from '../../models/basketproduct';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute } from '@angular/router';
import { Grape } from '../../models/grape';
import { Router } from '@angular/router';
import { BasketService } from '../../services/basket.service';
import { CategoryService } from '../../services/category.service';
import { Globals } from '../../globals';
import { Category } from 'src/app/models/category';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  public product: Product;
  public basketProduct: BasketProduct;
  public quantityForm: FormGroup;
  public grapes: Grape[] = null;
  public category: Category;
  stock: { inventoryBottlesStaMaria: string, inventoryBottlesCaracasbaai: string } | undefined = { inventoryBottlesCaracasbaai: '-', inventoryBottlesStaMaria: '-' };

  constructor(
    private activatedRoute: ActivatedRoute,
    public productService: ProductService,
    public basketService: BasketService,
    public categoryService: CategoryService,
    public globals: Globals,
    private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.quantityForm = this.formBuilder.group({
      quantity: ['']
    });

    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.productID) {
        this.getProductByID(routeParams.productID, this.globals.country);
        this.setValue(0);
        this.getStocks(routeParams.productID);

      }
    });

    this.globals.refreshFilterAction = function () {
      location.reload();
    };
  }

  getStocks(productID: any) {
    console.log("Getting stock for: ", productID);
    this.httpClient.get<{ inventoryBottlesStaMaria: string, inventoryBottlesCaracasbaai: string }>(`https://bottles.westream.cloud/product-info/${productID}`).subscribe((result) => {
      console.log('result', result);

      if (result) {
        this.stock = result;
      }

    });
  }

  getBasketProduct(product: Product) {
    this.basketProduct = this.basketService.getBasketItem(product);
    var value = this.basketProduct && this.basketProduct.amount ? this.basketProduct.amount : 0;
  }

  getBasketAmount() {
    return this.basketProduct && this.basketProduct.amount ? this.basketProduct.amount : 0;
  }

  setValue(data: number): void {
    this.quantityForm.setValue({
      quantity: data
    });
  }

  addProduct() {
    var value = this.quantityForm.value.quantity;
    value = Math.floor(value);
    this.add(this.product, value);
    this.getBasketProduct(this.product);
  }

  public amountCoffees: Object = [
    { name: 0 },
    { name: 10 },
    { name: 20 },
    { name: 30 },
    { name: 40 },
    { name: 50 },
    { name: 60 },
    { name: 70 },
    { name: 80 },
    { name: 90 },
    { name: 100 },
    { name: 150 }
  ]

  getProductByID(productID: number, country: string) {
    this.productService.getByID(productID, country)
      .subscribe(data => {
        this.product = data;
        // if(data.grapes){
        //   this.grapes = data.grapes; 

        // }

        this.getCategoryID(data.categoryID)
        this.getBasketProduct(data);
      });
  }

  getCategoryID(categoryID: number) {
    this.categoryService.getByID(categoryID)
      .subscribe(data => {
        this.category = data;
      });
  }

  setFilter(type, filter, id?) {
    id = id == 'undefined' ? '' : id;
    let state = { filters: filter, types: type };

    this.router.navigateByUrl(this.category.nameID + '/1/12/' + id, { state: state });
  }

  add(product: any, amount: number) {
    amount = Math.floor(amount);
    this.basketService.addProduct(product, amount);
    this.getBasketProduct(this.product);
  }

}
