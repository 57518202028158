<form [formGroup]="addHomeItemProductForm">
    <div class="wrapper main overflow">
        <div class="category-title">Add Home Item Product</div>
        <div class="row-">
            <div class="col-right">
                <div class="row">
                    <div style="text-align: center;" class="col col-img">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div class="col-left">
                <div style="margin-bottom: 40px;">
                    <span>Home Item Name</span><br />
                    <select formControlName="homeItemID" class="form-control">
                        <option [value]="hi.homeItemID"  *ngFor="let hi of homeItems">{{hi.name}}</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Code</span><br />
                    <input formControlName="productCode" type="text" class="form-control" id="productCode" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Start Date</span><br />
                    <input ngbDatepicker #s="ngbDatepicker" (click)="s.toggle()"  formControlName="startDate" type="text" class="form-control" id="startDate" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>End Date</span><br />
                    <input ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()"  formControlName="endDate" type="text" class="form-control" id="endDate" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Position</span><br />
                    <input formControlName="position" type="text" class="form-control" id="position" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Active</span><br /> 
                    <select formControlName="active" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>PIN</span><br />
                    <input formControlName="pin" type="password" class="form-control" id="pin" placeholder="">
                </div>
                <div style="height: 30px;">
                    <div *ngIf="updated">
                        Succesfully Created
                    </div>
                    <div *ngIf="pin === false">
                        Invalid pin
                    </div>
                </div>
                <div style="padding:0px 0 50px 0px ;">
                    <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                        <span *ngIf="!loading">Add Home Item Product</span>
                        <span *ngIf="loading">
                            Inserting Home Item Product&nbsp;&nbsp;<img height="10"
                                src="../../../assets/spinners/three-dots.svg" />
                        </span>
                    </div>
                </div>
                <div style="height: 50px;">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</form>