import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getSubcategoryByCategoryNameID(categoryNameID:number): Observable<Category[]> {
    return this.http.post<Category[]>(`${this.globals.serverlink}getSubcategoryByCategoryNameID.php`, {categoryNameID});
  }

  getByID(categoryID:number): Observable<Category> {
    return this.http.post<Category>(`${this.globals.serverlink}getCategoryByID.php`, {categoryID});
  }

  getAll(): Observable<Category[]> {
    return this.http.post<Category[]>(`${this.globals.serverlink}getCategories.php`, {});
  }
}
