import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subcategory } from '../models/subcategory';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  
  constructor(
    private http: HttpClient,
    private globals: Globals) { }



  getAll(): Observable<Subcategory[]> {
    return this.http.post<Subcategory[]>(`${this.globals.serverlink}getSubcategories.php`, {});
  }
}
