<div class="header-wrapper disable-on-mobile">
    <div [ngClass]="globals.country == 'cur' ? 'backgroundImageCur' : 'backgroundImageBon'" class="home-header">
        &nbsp;
        <!-- <video *ngIf="globals.country == 'cur'" style="margin-top: -270px;" autoplay loop [muted]="'muted'" id="myVideo">
            <source [src]="globals.videoSrc" type="video/mp4">
        </video>
        <video *ngIf="globals.country == 'bon'" style="margin-top: -270px;" autoplay loop [muted]="'muted'" loadstart="d" id="myVideo">
            <source [src]="globals.videoSrc" type="video/mp4">
        </video> -->
    </div>
</div>

<div class="header-wrapper show-on-mobile">
    <div style="line-height: 50px;">
        <i class="fas fa-clock"></i>&nbsp;&nbsp;<span>Order before 12pm delivery the same day</span>
    </div>
</div>

<div class="show-on-mobile">
    <div class="homepage-buttons-wrapper">
        <div routerLink="/wine/1/12" class="homepage-button">WINES</div>
        <div routerLink="/spirits/1/12" class="homepage-button">SPIRITS</div>
    </div>
    <div class="homepage-buttons-wrapper">
        <div routerLink="/beers/1/12" class="homepage-button">BEERS</div>
        <div routerLink="/water/1/12" class="homepage-button">WATER & BEVERAGES</div>
    </div>
    <div class="homepage-buttons-wrapper">
        <div routerLink="/coffee/1/12" class="homepage-button">COFFEE</div>
        <div routerLink="/deals/1/12" class="homepage-button">DEALS</div>
    </div>
</div>


<div class="header-board-wrapper">
    <div class="header-board">
        <div class="header-board-items">
            <i class="fas fa-clock"></i>&nbsp;&nbsp;<span>Order before 12pm delivery the same day</span>
        </div>
        <div class="header-board-items">
            <i class="fad fa-truck"></i>&nbsp;&nbsp;<span>Free delivery with a minimum order of <span
                    *ngIf="globals.country == 'cur'">ANG 200.00</span><span
                    *ngIf="globals.country == 'bon'">$100.00</span></span>
        </div>
        <div class="header-board-items item-3">
            <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;<span>We accept pin/swipe payments only</span>
        </div>
    </div>
</div>

<div *ngFor="let item of homeItemProducts" class="category-section top-padding">
    <div *ngIf="item.title" class="category-title">
        {{item.title}}
    </div>
    <div *ngIf="item.description" [innerHTML]="item.description" class="category-section-description">
    </div>
    <div class="href-btn-wrapper wrapper disable-on-mobile">
        <span *ngIf="item.buttonName" class="href-btn" (click)="navigateTo('/'+item.redirectUrl)">{{item.buttonName}}
            &nbsp;&nbsp;
            <i class="fad fa-chevron-circle-right"></i>
        </span>
    </div>
    <app-product-list-h [products]="item.products">Loading</app-product-list-h>
    <div class="big-button-wrapper show-on-mobile">
        <div (click)="navigateTo('/'+item.redirectUrl)" class="big-button">
            <div> 
                {{item.buttonName}}
            </div>
            <div style="margin-left: auto;">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
</div>



<!-- <div *ngIf="deals" class="category-section top-padding">
    <div class="href-btn-wrapper wrapper disable-on-mobile">
        <span class="href-btn" (click)="navigateTo('deals/1/12')">See all deals &nbsp;&nbsp;
            <i class="fad fa-chevron-circle-right"></i>
        </span>
    </div>
    <app-product-list-h [products]="deals">Loading</app-product-list-h>
    <div class="big-button-wrapper show-on-mobile">
        <div (click)="navigateTo('deals/1/12')" class="big-button">
            <div>
                See All Deals
            </div>
            <div style="margin-left: auto;">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
</div> -->

<div style="margin-top: 50px;" class="wrapper mobile">
    <div class="category-section large-padding">
        <div class="category-title">
            WINES
        </div>
        <div class="category-section-description">
            Quality and consistency are our top priority. We find it very important to work with the best wineries in
            the world, who are renowned for their high quality and consistent wines for the past decades. We are proud
            to work with the best winemakers in the world.
        </div>
        <div class="href-btn-wrapper">
            <span class="href-btn" (click)="navigateTo('wine/1/12')">See all wines &nbsp;&nbsp;<i
                    class="fad fa-chevron-circle-right"></i></span>
        </div>
        <div class="category-carousel">
            <div *ngFor="let item of topWines" class="home-item-block">
                <div [ngStyle]="{'background-color' : item.color}" class="product-backdrop">
                    &nbsp;
                </div>
                <div class="product-image" (click)="navigateTo('product/'+ item.productID)">
                    <img class="grow" style="z-index: 100; " src="../../../assets/products/{{item.code}}.png"
                        height="300" /><br />
                    <span class="product-text">{{item.name}}</span>
                </div>
            </div>
        </div>

    </div>


    <div class="category-section large-padding mobile">
        <div class="category-title" style="text-align: center;">
            SPIRITS
        </div>
        <div class="category-section-description">
            When it comes to spirits, we also aim for the highest quality brands in the world. This results in a
            broad
            range
            of spirits, including some very exclusive ones. And of course, always at the right price.
        </div>
        <div class="href-btn-wrapper">
            <span (click)="navigateTo('spirits/1/12')" class="href-btn">See all spirits &nbsp;&nbsp;
                <i class="fad fa-chevron-circle-right"></i>
            </span>
        </div>
        <div class="category-carousel">
            <div *ngFor="let item of top" class="home-item-block">
                <div class="product-backdrop">
                    &nbsp;
                </div>
                <div class="product-image" (click)="setFilter('subcategories', item, '26', 'spirits')">
                    <img class="grow" src="../../../assets/products/{{item.code}}.png" height="300" /><br />
                    <span class="product-text">{{item.name}}</span>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="category-section coffee-theme coffee-padding mobile">
    <div>
        <div class="category-title">
            <img src="../../../assets/logos/nespresso.png" width="150" />
        </div>
        <div class="category-section-description">
            Bottles is the official Distributor of Nespresso Coffee for Cura&ccedil;ao, Aruba and Bonaire. We offer more
            than
            23 different flavors and a range of machines. Discover which coffees you like and order them right
            away.
        </div>
        <div class="href-btn-wrapper wrapper">
            <span (click)="navigateTo('coffee/1/12')" class="href-btn">See all coffees &nbsp;&nbsp;<i
                    class="fad fa-chevron-circle-right"></i></span>
        </div>
        <div class="category-carousel carousel-coffee">
            <div class="home-coffee-block" *ngFor="let item of topCoffee">
                <div class="coffee-image">
                    <img class="grow" src="../../../assets/products/{{item.code}}.png" height="120" /><br />
                    <span class="coffee-text">{{item.name}}</span>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="wrapper mobile">
    <div class="category-section coffee-padding">
        <div class="category-title" style="text-align: center;">
            BEERS
        </div>
        <div class="href-btn-wrapper">
            <span (click)="navigateTo('beers/1/12')" class="href-btn">See all beers &nbsp;&nbsp;<i
                    class="fad fa-chevron-circle-right"></i></span>
        </div>
        <div class="category-carousel">
            <div *ngFor="let item of topBeers" class="home-item-block">
                <div (click)="setFilter('subcategories', item, '26', 'beers')" style="padding: 20px 0;"
                    class="product-image">
                    <img class="grow" style="z-index: 100; " src="../../../assets/products/{{item.code}}.png"
                        height="160" /><br />
                    <span
                        style="display: inline-block; padding-top: 15px; font-weight: 600; font-size: 14px; text-transform: uppercase;">{{item.name}}</span>
                </div>
            </div>
        </div>

    </div>
</div>