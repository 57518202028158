<form [formGroup]="productForm">
    <div class="wrapper main overflow">
        <div class="category-title">Add Product</div>
        <div class="row-">
            <div class="col-right">
                <div class="row">
                    <div style="text-align: center;" class="col col-img">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div class="col-left">
                <div style="margin-bottom: 40px;">
                    <span>Is this product a Deal?</span><br />
                    <select formControlName="deal" class="form-control">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Name</span><br />
                    <input formControlName="name" type="text" class="form-control" id="name" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Code</span><br />
                    <input formControlName="code" type="text" class="form-control" id="code" placeholder="">
                </div>
                <div *ngIf="productForm.value.deal === '0'" style="margin-bottom: 40px;">
                    <span>Product Category</span><br />
                    <select formControlName="categoryID" class="form-control" id="category">
                        <option *ngFor="let category of categories" [value]="category.categoryID">{{category.name}}
                        </option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '8'"
                    style="margin-bottom: 40px;">
                    <span>Product Type</span><br />
                    <select formControlName="subcategoryID" class="form-control" id="subcategory">
                        <option *ngFor="let subcategory of subcategories" [value]="subcategory.subcategoryID">
                            {{subcategory.name}}</option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '8'"
                    style="margin-bottom: 40px;">
                    <span>Brand</span><br />
                    <select formControlName="brandID" class="form-control" id="brand">
                        <option *ngFor="let brand of brands" [value]="brand.brandID">{{brand.name}}</option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Taste Profile</span><br />
                    <select formControlName="tasteprofileID" class="form-control" id="tasteprofile">
                        <option *ngFor="let tasteprofile of tasteprofiles" [value]="tasteprofile.tasteprofileID">
                            {{tasteprofile.name}}</option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '8'"
                    style="margin-bottom: 40px;">
                    <span>Country</span><br />
                    <select formControlName="countryID" class="form-control" id="country">
                        <option [value]="null">None</option>
                        <option *ngFor="let country of countries" [value]="country.countryID">{{country.name}}</option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '8'"
                    style="margin-bottom: 40px;">
                    <span>Region</span><br />
                    <select formControlName="regionID" class="form-control" id="region">
                        <option [value]="null">None</option>
                        <option *ngFor="let region of regions" [value]="region.regionID">{{region.name}}</option>
                    </select>
                </div>
                <div style="display: flex;">
                    <div style="margin-bottom: 40px;">
                        <span>Product Price Curacao</span><br />
                        <input formControlName="basePrice" type="text" class="form-control" id="basePrice"
                            placeholder="">
                    </div>
                    <div *ngIf="productForm.value.deal === '1'" style="margin-bottom: 40px; margin-left: 50px;">
                        <span>Deal Price Curacao</span><br />
                        <input formControlName="dealPriceCur" type="text" class="form-control" id="dealPriceCur"
                            placeholder="">
                    </div>
                    <div style="margin-bottom: 40px; margin-left: 50px;">
                        <span>Publish in Curacao</span><br />
                        <select formControlName="publishCur" class="form-control">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div style="display: flex;">
                    <div style="margin-bottom: 40px;">
                        <span>Product Price Bonaire</span><br />
                        <input formControlName="basePriceBon" type="text" class="form-control" placeholder="">
                    </div>
                    <div *ngIf="productForm.value.deal === '1'" style="margin-bottom: 40px; margin-left: 50px;">
                        <span>Deal Price Bonaire</span><br />
                        <input formControlName="dealPriceBon" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px; margin-left: 50px;">
                        <span>Publish in Bonaire</span><br />
                        <select formControlName="publishBon" class="form-control">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Active?</span><br />
                    <select formControlName="active" class="form-control">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Button Name</span><br />
                    <select formControlName="buttonNameID" class="form-control">
                        <option [value]="btn.buttonNameID" *ngFor="let btn of buttonnames">{{btn.buttonName}}</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Button Action</span><br />
                    <select formControlName="buttonActionID" class="form-control">
                        <option [value]="bta.buttonActionID" *ngFor="let bta of buttonactions">{{bta.buttonActionName}}
                        </option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Year</span><br />
                    <input formControlName="year" type="text" class="form-control" placeholder="">
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '6'"
                    style="margin-bottom: 40px;">
                    <span>Litrage</span><br />
                    <input formControlName="litrage" type="text" class="form-control" placeholder="">
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID !== '6'"
                    style="margin-bottom: 40px;">
                    <span>Alcohol Percentage</span><br />
                    <input formControlName="alcoholPercentage" type="text" class="form-control" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Position</span><br />
                    <input formControlName="ranking" type="text" class="form-control" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Product Info</span><br />
                    <textarea style="height: 100px;" formControlName="info" class="form-control"></textarea>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Food Pairing</span><br />
                    <textarea style="height: 100px;" formControlName="foodPairing" class="form-control"></textarea>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Colors</span><br />
                    <select formControlName="colorID" class="form-control">
                        <option [value]="color.colorID" *ngFor="let color of colors">{{color.name}}</option>
                    </select>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Ingredients</span><br />
                    <textarea style="height: 100px;" formControlName="ingredients" class="form-control"></textarea>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <!-- <span>Grape</span><br />
                    <textarea style="height: 100px;" formControlName="grape" class="form-control"></textarea> -->
                    <span>Grapes</span><br />

                    <div style="display: flex;">
                        <select formControlName="grapeID" class="form-control">
                            <option [value]="i" *ngFor="let grape of grapes; let i = index">{{grape.name}}</option>
                        </select>
                        <div (click)="addGrapes()">
                            Add Grape   
                        </div>
                    </div>
                    <div *ngIf="addedGrapes">
                        <div *ngFor="let added of addedGrapes; let i = index">
                            {{added.name}} <span (click)="removeGrapes(i)">[x]</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Aroma</span><br />
                    <textarea style="height: 100px;" formControlName="aroma" class="form-control"></textarea>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Taste</span><br />
                    <textarea style="height: 100px;" formControlName="taste" class="form-control"></textarea>
                </div>
                <div *ngIf="productForm.value.deal === '0' && productForm.value.categoryID === '2'"
                    style="margin-bottom: 40px;">
                    <span>Serve</span><br />
                    <textarea style="height: 100px;" formControlName="serve" class="form-control"></textarea>
                </div>

                <div style="margin-bottom: 40px;">
                    <span>PIN</span><br />
                    <input formControlName="pin" type="password" class="form-control" id="pin" placeholder="">
                </div>
                <div style="height: 30px;">
                    <div *ngIf="updated">
                        Succesfully Created
                    </div>
                    <div *ngIf="pin === false">
                        Invalid pin
                    </div>
                </div>
                <div style="padding:0px 0 50px 0px ;">
                    <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                        <span *ngIf="!loading">Add Product</span>
                        <span *ngIf="loading">
                            Inserting Product&nbsp;&nbsp;<img height="10"
                                src="../../../assets/spinners/three-dots.svg" />
                        </span>
                    </div>
                </div>
                <div style="height: 50px;">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</form>