import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonService } from 'src/app/services/button.service';
import { HomeitemService } from 'src/app/services/homeitem.service';
import { HomeitemproductService } from 'src/app/services/homeitemproduct.service';


@Component({
  selector: 'app-addhomeitemproduct',
  templateUrl: './addhomeitemproduct.component.html',
  styleUrls: ['./addhomeitemproduct.component.scss']
})
export class AddhomeitemproductComponent implements OnInit {
  public addHomeItemProductForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;
  public homeItems: any;

  constructor(
    public homeItemService: HomeitemService,
    public homeItemProductService: HomeitemproductService,
    private formBuilder: FormBuilder,
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void { 
    this.addHomeItemProductForm = this.formBuilder.group({
      homeItemID: [''],
      productCode: [''],
      active: [''],
      startDate: [''],
      endDate: [''],
      position: [''],
      redirectUrl: [''], 
      pin: ['']
    });
    this.getHomeItems(); 
  }

  getHomeItems() {
    this.homeItemService.getHomeItems()
      .subscribe(data => {
        this.homeItems = data;
      });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.addHomeItemProductForm.value;
      formdata.action = 'create';

      this.homeItemProductService.addHomeItemProduct(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
        });
    }

  }

}
