<form [formGroup]="orderForm">
    <div class="wrapper main overflow">
        <div class="category-title">ORDER</div>
        <div class="row-">
            <div class="col-left">
                <div class="filter countries-filter">
                    <div class="filter-title">ORDER DETAILS</div>
                    <div style="margin-top: 20px;">

                        <div class="form-group">
                            <label for="exampleInputEmail1">Store Pick Up or Delivery?</label>
                            <select (change)="setOrderStatus()" formControlName="ordertype" class="form-control">
                                <option value="store">Store Pick Up</option>
                                <option value="delivery">Delivery</option>
                            </select>
                        </div>
                        <div style="margin-top: 25px;">
                            <label for="exampleInputEmail1">Timeslot</label>
                            <select formControlName="timeslot" class="form-control">
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                            </select>
                        </div>
                        <!-- <div style="margin-top: 25px;">
                            <label for="exampleInputEmail1">Pay Online?</label>
                            <select formControlName="payOnline" class="form-control">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div> -->
                        <div style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label>
                                    <span *ngIf="orderForm.value.ordertype === 'store'">Pick Up</span>
                                    <span *ngIf="orderForm.value.ordertype === 'delivery'">Delivery</span> Date *
                                </label>
                                <input (click)="d.toggle()" autocomplete="off" class="form-control"
                                    formControlName="deliverDate" ngbDatepicker #d="ngbDatepicker" placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('deliverDate')">
                                    Enter a valid date
                                </div>
                            </div>
                        </div>
                        <div *ngIf="orderForm.value.ordertype === 'store'" style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label for="exampleInputEmail1">Vehicle Plate Number *</label>
                                <input (keyup)="setFormData()" formControlName="carplate" type="text"
                                    class="form-control" id="carPlate" aria-describedby="carplate" placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('carplate')">
                                    Enter a valid vehicle plate
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label for="exampleInputEmail1">Address *</label>
                                <input (keyup)="setFormData()" formControlName="address" type="text"
                                    class="form-control" id="address" aria-describedby="addressHelp" placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('address')">
                                    Enter a valid address
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label for="exampleInputEmail1">First name *</label>
                                <input (keyup)="setFormData()" formControlName="firstname" type="text"
                                    class="form-control" id="firstname" aria-describedby="firstnameHelp" required
                                    placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('firstname')">
                                    <div *ngIf="firstname.errors.required">
                                        First name is required
                                    </div>
                                    <div *ngIf="firstname.errors.minlength">
                                        Minimum 2 characters long
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <label for="exampleInputEmail1">Last name *</label>
                                <input (keyup)="setFormData()" formControlName="lastname" type="text"
                                    class="form-control" id="lastname" aria-describedby="lastnameHelp" placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('lastname')">
                                    <div *ngIf="lastname.errors.required">
                                        Last name is required
                                    </div>
                                    <div *ngIf="lastname.errors.minlength">
                                        Minimum 2 characters long
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label for="exampleInputEmail1">Email *</label>
                                <input (keyup)="setFormData()" formControlName="email" type="email" class="form-control"
                                    id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('email')">
                                    Enter a valid email
                                </div>
                            </div>
                            <div class="col">
                                <label for="telephoneInput">Telephone *</label>
                                <input (keyup)="setFormData()" formControlName="telephone" type="text"
                                    class="form-control" id="telephoneInput" aria-describedby=" telephoneHelp"
                                    placeholder="">
                                <div class="invalid-input" *ngIf="isFieldValid('telephone')">
                                    Enter a valid telephone number
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 25px;">
                            <label>Do you want us to wrap your order as a gift?</label>
                            <select formControlName="wrap" class="form-control">
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        <div style="margin-top: 25px;">
                            <label>Would you like to stay up to date on our exclusive deals, tastings and upcoming
                                events via Whatsapp and Email?</label>
                            <select formControlName="uptodate" class="form-control">
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        <div style="margin-top: 25px;" class="row">
                            <div class="col">
                                <label>Remarks</label>
                                <textarea (keyup)="setFormData()" formControlName="remarks"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                        <div style="font-size: 14px; margin-top: 30px;">
                            All fields marked with * are required
                        </div>
                    </div>
                    <div class="deliver-info">
                        <div style="margin-top: 20px; margin-bottom: 20px;" class="filter-title">DELIVERY INFO</div>
                        <div style="font-size: 14px;">
                            <b>Monday – Saturday</b><br />
                            - Order before 12pm delivery the same day.<br />
                            - Order after 12pm delivery the next day.<br />
                            - Please let us know which time slot (morning or afternoon) you prefer.<br />
                            - We accept pin/swipe payments only.<br />
                            <span *ngIf="globals.country == 'cur'">
                                - We offer FREE delivery with a minimum order of ANG 200.00.<br />
                                - Our standard delivery costs are ANG 15.00.<br />
                                - Please call for deliveries in Banda Bou at +5999 4621465.<br />
                            </span>
                            <span *ngIf="globals.country == 'bon'">
                                - We offer FREE delivery with a minimum order of $100.00.<br />
                                - Our standard delivery costs are $10.00.<br />
                                - Please call for deliveries in Rincon at +599 7179470.<br />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right">
                <div class="filter countries-filter">
                    <div class="filter-title" style="display: flex;">
                        <div>Shopping List</div>
                        <!-- <div style="text-align: right; margin-left: auto;">Delete</div> -->
                    </div>

                    <div *ngIf="basketService.getSubTotal() !== '0.00'" style="margin-top: 20px;">
                        <table style="font-size: 14px;" width="100%" cellspacing="0" cellpadding="0">
                            <tr style="font-weight: 600;">
                                <td width="65">
                                    Product
                                </td>
                                <td width="230">
                                    &nbsp;
                                </td>
                                <td style="text-align: left;" width="120">
                                    Amount
                                </td>
                                <td align="right">
                                    Price
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr [ngClass]="{'product-not-available' : globals.country == 'cur' && basketProduct.product.publishCur == 0 || globals.country == 'cur' && basketProduct.product.availableCur == 0  || globals.country == 'bon' && basketProduct.product.publishBon == 0 || globals.country == 'bon' && basketProduct.product.availableBon == 0}"
                                *ngFor="let basketProduct of basket">
                                <td style="padding: 20px 0; cursor: pointer;">
                                    <img (click)="navigateTo('product/'+basketProduct.product.productID)"
                                        src="../../../assets/products/{{basketProduct.product.code}}B.png" height="85"
                                        height="85" />
                                </td>
                                <td valign="top" style="padding: 20px 0;">
                                    {{basketProduct.product.name}}<br />
                                    <span
                                        *ngIf="globals.country == 'cur' && basketProduct.product.publishCur == 0 || globals.country == 'cur' && basketProduct.product.availableCur == 0  || globals.country == 'bon' && basketProduct.product.publishBon == 0 || globals.country == 'bon' && basketProduct.product.availableBon == 0">[Not
                                        Available]</span>
                                </td>
                                <td valign="top" style="padding: 20px 0; text-align: left;">
                                    <input style="width: 50px; font-size: 14px; text-align:center; padding-right: 0;"
                                        class="form-control" [value]="basketProduct.amount"
                                        (focusout)="setValue(basketProduct, $event.target.value)" type="number" />

                                </td>
                                <td valign="top" style="padding: 20px 0; text-align: right;">
                                    {{basketProduct.total.toFixed(2)}}
                                </td>
                                <td valign="top" style="padding: 20px 0; text-align: right;" width="20">
                                    <span (click)="deleteProduct(basketProduct.product)"><i style="cursor: pointer;"
                                            class="fas fa-trash"></i></span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: left;">
                                    Subtotal
                                </td>
                                <td style="text-align: right;">
                                    {{basketService.getSubTotal()}}
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: left;">
                                    Delivery Costs
                                </td>
                                <td style="text-align: right;">
                                    {{basketService.getDeliveryCosts()}}
                                </td>
                                <td style="text-align: right;">
                                    +
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: left; font-weight: 600;">
                                    Total in {{basketService.getCurrency()}}
                                </td>
                                <td style="text-align: right; font-weight: 600;">
                                    {{basketService.getTotal()}}
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="basketService.getSubTotal() !== '0.00' && orderForm.value.payOnline === 'No'" style="padding: 50px 0 50px 0px ;">
                        <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submitOrder()">
                            <span *ngIf="!loading">Submit Order</span>
                            <span *ngIf="loading">
                                Submitting your order&nbsp;&nbsp;<img height="10"
                                    src="../../../assets/spinners/three-dots.svg" />
                            </span>
                        </div>
                    </div>
                    <div *ngIf="basketService.getSubTotal() !== '0.00' && orderForm.value.payOnline === 'Yes'"
                        style="padding: 50px 0 50px 0px ;">
                        <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="payOrder()">
                            <span *ngIf="!loading">Pay Order</span>
                            <span *ngIf="loading">
                                Submitting your order&nbsp;&nbsp;<img height="10"
                                    src="../../../assets/spinners/three-dots.svg" />
                            </span>
                        </div>
                    </div>
                    <div *ngIf="basketService.getSubTotal() == '0.00'" style="margin-top: 20px;">
                        Your basket is empty
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>