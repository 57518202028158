import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonService } from 'src/app/services/button.service';
import { HomeitemService } from 'src/app/services/homeitem.service';

@Component({
  selector: 'app-edithomeitems',
  templateUrl: './edithomeitems.component.html',
  styleUrls: ['./edithomeitems.component.scss']
})
export class EdithomeitemsComponent implements OnInit {
  public currentHomeItem: any;
  public addHomeItemForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;
  public buttonnames: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public homeItemService: HomeitemService,
    private router: Router,
    private formBuilder: FormBuilder,
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void {
    this.addHomeItemForm = this.formBuilder.group({
      name: [''],
      homeItemID: [''],
      buttonNameID: ['0'],
      addActionButton: ['0'],
      addDescription: ['0'],
      description: [''],
      addTitle: ['0'],
      title: ['0'],
      active: [''],
      publishCur: ['0'],
      publishBon: ['0'],
      redirectUrl: [''],
      pin: ['']
    });

    this.getButtonNames();

    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.id) {
        this.getCurrentHomeItem(routeParams.id);
      
      }
    });
  }

  setValue(data: any): void {
    this.addHomeItemForm.setValue({
      name: data.name,
      buttonNameID: data.buttonNameID ? data.buttonNameID : '0',
      addActionButton: data.buttonNameID ? '1' : '0',
      addTitle: data.title ? '1' : '0',
      addDescription: data.description ? '1' : '0', 
      description: data.description,
      title: data.title,
      publishCur: data.publishCur,
      publishBon: data.publishBon,
      active: data.active,
      homeItemID: data.homeItemID ? data.homeItemID : '',
      redirectUrl: data.redirectUrl,
      pin: ''
    });
  }

  getButtonNames() {
    this.buttonService.getButtonNames()
      .subscribe(data => {
        this.buttonnames = data;
      });
  }


  getCurrentHomeItem(homeItemID) {
    this.homeItemService.getHomeItemByID(homeItemID)
      .subscribe(data => {
        this.currentHomeItem = data;
        this.setValue(data);
      });
  }

  setTitle(){
    if(this.addHomeItemForm.value.addTitle == '0'){
      this.addHomeItemForm.controls.title.setValue('');
    }
  }

  setActionButton(){
    if(this.addHomeItemForm.value.addActionButton == '0'){
      this.addHomeItemForm.controls.buttonNameID.setValue('0');
      this.addHomeItemForm.controls.redirectUrl.setValue('');
    }
  }

  setDescription(){
    if(this.addHomeItemForm.value.addDescription == '0'){
      this.addHomeItemForm.controls.description.setValue('');
    }
  }


  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.addHomeItemForm.value;
      formdata.action = 'update';

      this.homeItemService.addHomeItem(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
          if(data.updated){
            this.router.navigateByUrl('homeitems');
          }
        });
    }

  }

}
