<form [formGroup]="loginForm">
    <div class="wrapper main overflow">
        <div class="category-title pagination-disable-mobile">
            <div>Log In</div>
        </div>
        <div>
            This log in only accessible for suppliers
        </div>
        <div class="zoneActivitiesTitle">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit('')">
                <input autocomplete="off" class="form-control" formControlName="username" placeholder="Email">
                <input autocomplete="off" type="password" class="form-control" formControlName="password"
                    placeholder="Password">
                <button type="submit" class="buttons uni-blue">Sign In</button>
            </form>
        </div>
        <div style="height: 20px;">
            &nbsp;
        </div>
    </div>
</form>