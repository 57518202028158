import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class HomeitemService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }


  addHomeItem(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}addHomeItem.php`, {formdata});
  }

  getHomeItems(): any {
    return this.http.post(`${this.globals.serverlink}getHomeItems.php`, {});
  }

  getHomeItemByID(homeItemID): any {
    return this.http.post(`${this.globals.serverlink}getHomeItemByID.php`, homeItemID);
  }


 
}
