import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Region } from '../models/region';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getRegionByCategoryNameID(categoryNameID:number): Observable<Region[]> {
    return this.http.post<Region[]>(`${this.globals.serverlink}getRegionByCategoryNameID.php`, {categoryNameID});
  }

  getAll(): Observable<Region[]> {
    return this.http.post<Region[]>(`${this.globals.serverlink}getRegions.php`, {});
  }

  addRegion(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}addRegion.php`, {formdata});
  }
}
