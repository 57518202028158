<div class="sidenav-wrapper">   
    <div [ngClass]="{'side-nav-on' : globals._navState}" class="sidenav">
        <div style="position: absolute; padding: 7px 20px;">
            <span style="position: absolute; margin-left: 2px; background-color: black; color: #fff; font-size: 5.5px; top: 57px;">
                WINE & SPIRITS
            </span>
            <img src="../../../assets/logo.svg" height="60">
        </div>
        <div style="display: flex;" data-toggle="modal" class="sidenav-item">
            <div style="margin-left: auto; padding: 10px 18px;" (click)="toggleNav()" class="item-icon">
                <i style="font-size: 30px; color:#fff;" class="fal fa-times"></i>
            </div>
        </div>
        <div class="sidenav-main">
            <div (click)="navigateTo(item)" *ngFor="let item of navBtns" data-toggle="modal" class="sidenav-item">
                <div [ngClass]="{'item-icon-on' : globals.currentPage == item.route}" [innerHTML]="item.icon"
                    class="item-icon">
                    <!-- <div [ngClass]="{'styling-blocks-on' :globals.currentPage == item.route}" class="styling-blocks">
                        &nbsp;
                    </div> -->
                </div>
                <div [ngClass]="{'item-text-on' : globals.currentPage == item.route}" class="item-text">{{item.name}}
                </div>
                <div class="item-highlight">
                    &nbsp;
                </div>
            </div>
            <div routerLink="order/+ {{globals.country}})" data-toggle="modal" class="sidenav-item">
                <div [ngClass]="{'item-icon-on' : globals.currentPage == 'order'}" class="item-icon">
                    <i class="fal fa-shopping-basket"></i>
                </div>
                <div [ngClass]="{'item-text-on' : globals.currentPage == 'order'}" class="item-text">Basket&nbsp;
                    {{basketService.getCurrency()}} {{basketService.getSubTotal()}}</div>
                <div class="item-highlight">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</div>