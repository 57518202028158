import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    public globals: Globals
  ) { }

  ngOnInit(): void {
  }

  instagramURL() {
    var url = null;
    switch (this.globals.country) {
      case 'cur':
        url = "https://www.instagram.com/bottlescuracao/";
        break;
      case 'bon':
        url = "https://www.instagram.com/bottlesbonaire/";
        break;
    }
    window.open(url, '_blank');
  }

  facebookURL() {
    var url = null;
    switch (this.globals.country) {
      case 'cur':
        url = "https://www.facebook.com/bottlescur/";
        break;
      case 'bon':
        url = "https://www.facebook.com/Bottles-Bonaire-102067207971793/";
        break;
    }
    window.open(url, '_blank');
  }

}
