import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonService } from 'src/app/services/button.service';
import { HomeitemService } from 'src/app/services/homeitem.service';

@Component({
  selector: 'app-addhomeitem',
  templateUrl: './addhomeitem.component.html',
  styleUrls: ['./addhomeitem.component.scss']
})
export class AddhomeitemComponent implements OnInit {
  public addHomeItemForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;
  public buttonnames: any;

  constructor(
    public homeItemService: HomeitemService,
    private formBuilder: FormBuilder,
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void { 
    this.addHomeItemForm = this.formBuilder.group({
      name: [''],
      homeItemID: [''],
      buttonNameID: ['0'],
      addActionButton: ['0'],
      addDescription: ['0'],
      description: [''],
      addTitle: ['0'],
      title: [''],
      active: [''],
      publishCur: ['0'],
      publishBon: ['0'],
      redirectUrl: [''],
      pin: ['']
    });
    this.getButtonNames();
  }

  getButtonNames() {
    this.buttonService.getButtonNames()
      .subscribe(data => {
        this.buttonnames = data;
      });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.addHomeItemForm.value;
      formdata.action = 'create';

      this.homeItemService.addHomeItem(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
        });
    }

  }

}
