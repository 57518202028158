import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeitemproductService } from 'src/app/services/homeitemproduct.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public deals: Product[];
  public country = null;
  public homeItemProducts: any = [];

  public top: Object = [
    { name: 'Whisky', color: '#52a1a0', code: '10447B', productID: '853' },
    { name: 'Rum', color: '#bbcde2', code: '10218B', productID: '697' },
    { name: 'Liqueurs', color: '#eac142', code: '10148B', productID: '10148B' },
    { name: 'Cognac', color: '#e3b8b8', code: '10012B', productID: '10012B' },
    { name: 'Gin', color: '#a23237', code: '10045B', productID: '554' }
  ]

  public topCoffee: Object = [
    { name: '', color: '#52a1a0', code: '40015B' },
    { name: '', color: '#bbcde2', code: '40006B' },
    { name: '', color: '#eac142', code: '40019B' },
    { name: '', color: '#e3b8b8', code: '40018B' },
    { name: '', color: '#a23237', code: '40012B' }
  ]

  public topWines: Object = [
    { name: '', color: '#bbcde2', code: '20030B', productID: '1211' },
    { name: '', color: '#52a1a0', code: '20215B', productID: '1347' },
    { name: '', color: '#eac142', code: '20276B', productID: '1378' },
    { name: '', color: '#e3b8b8', code: '20307B', productID: '1396' },
    { name: '', color: '#a23237', code: '20363B', productID: '1437' }
  ]

  public topBeers: Object = [
    { name: 'Heineken', color: '#52a1a0', code: '35006B' },
    { name: 'Heineken 0.0', color: '#eac142', code: '35007B' },
    { name: 'Amstel Bright', color: '#bbcde2', code: '35003B' },
    { name: 'Amstel', color: '#e3b8b8', code: '35001B' },
    { name: 'Lowlander', color: '#a23237', code: '35008B' }
  ]

  constructor(
    private productService: ProductService,
    private homeItemProductService: HomeitemproductService,
    public globals: Globals,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }


   
  ngOnInit(): void {
  
    window.scrollTo(0, 0);
    this.globals.refreshFilterAction = function (route, country) {   
       route.navigateByUrl('home/' + country);
    };


    this.globals.currentPage = 'home';
    this.country = this.globals.country;
    
    this.activatedRoute.params.subscribe(routeParams => {
      this.getDeals(4, this.globals.country);  
      this.getHomeItemProducts();
      // if(this.globals.country == 'cur'){
      
      //   this.globals.videoSrc = 'assets/vid/vidcuracao.mp4';
      // }else{
      //   this.globals.videoSrc = 'assets/vid/vidbonaire2.mp4';
      // }
    }); 
 
  }

  array(n: string): any[] {
    let item = parseInt(n);
    return Array(item);
  }

  getHomeItemProducts() {
    this.homeItemProductService.getHomeItemProducts(this.globals.country)
      .subscribe(data => {
        this.homeItemProducts = data.data;
      });
  }

  setFilter(type, filter, id, categoryNameID) {
    let state = { filters: filter, types: type };

    this.router.navigateByUrl(categoryNameID + '/1/12/');
  }

  navigateTo(href: string) {
    this.router.navigateByUrl(href);
  }

  getDeals(amount: number, country: string) {
    this.productService.getDeals(amount, country)
      .subscribe(data => {
        this.deals = data.products;
      });
  }

}
