import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationStart } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Category } from '../../models/category';
import { Country } from '../../models/country';
import { Brand } from '../../models/brand';
import { Product } from '../../models/product';
import { Color } from '../../models/color';
import { Region } from '../../models/region';
import { Grape } from '../../models/grape';
import { ProductService } from '../../services/product.service';
import { FiltersService } from '../../services/filters.service';
import { Filters } from '../../models/filters';
import { Intensity } from '../../models/intensity';
import { Globals } from '../../globals';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { BasketService } from '../../services/basket.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, first } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {
  public types: any;
  public fil: any;
  public category: string = null;
  public countries: Country[];
  public subcategories: any = [];
  public brands: Brand[];
  public colors: Color[];
  public products: Product[];
  public filters: Filters = new Filters;
  public regions: Region[];
  public intensities: Intensity[];
  public grapes: Grape[];
  public tasteprofiles: Tasteprofile[];
  public prices: any[];
  public currentOrder: string = "Best Seller";
  public routeID: string = null;
  public selectPageForm: FormGroup;
  public sideFilter: boolean = false;

  page: any = "";
  amountPages: any = false;
  currentPage: any = null;
  amountPerPage: any = false;
  amountResults: any = false;
  amountPagingBtns: number = 4;

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private productService: ProductService,
    private filtersService: FiltersService,
    public basketService: BasketService,
    public globals: Globals,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {

   
    this.selectPageForm = this.formBuilder.group({
      pageNumber: []
    });

 

    this.activatedRoute.params.subscribe(routeParams => {
      window.scrollTo(0, 0);

      if (routeParams.categoryNameID && routeParams.page && routeParams.amount) {
        this.page = routeParams.categoryNameID;
        var pagingData: any = { amount: routeParams.amount, page: routeParams.page }
        this.currentPage = routeParams.page;
        this.amountPerPage = routeParams.amount;
        var link = routeParams.categoryNameID + '/1/12';

        if (routeParams.id) {
          this.routeID = routeParams.id;
          link += '/' + this.routeID;
        }

        this.globals.currentPage = link;

        this.category = routeParams.categoryNameID;

        if (window.history.state && window.history.state.filters) {
          this.types = window.history.state.types;
          this.fil = window.history.state.filters;

          this.filters.pagingdata = pagingData;
          this.filters.currentcurrency = this.basketService.getCurrency();
          this.filters.category = this.category;
          this.filters.brands = [];
          this.filters.subcategories = [];
          this.filters.regions = [];
          this.filters.countries = [];
          this.filters.tasteprofiles = [];
          this.filters.prices = [];
          this.filters.grapes = [];
          this.filters.intensities = [];
          this.currentOrder = "Best Seller";
          this.filters.order = 'best';
          this.getFiltersByCategoryNameID(routeParams.categoryNameID);
        }
        else {
          if (this.filters.lastroute != this.page) {

            this.filters = new Filters;
            this.types = null;
            this.filters.brands = [];
            this.filters.subcategories = [];
            this.filters.regions = [];
            this.filters.countries = [];
            this.filters.tasteprofiles = [];
            this.filters.intensities = [];
            this.filters.prices = [];
            this.filters.grapes = [];
            this.filters.pagingdata = pagingData;
            this.filters.currentcurrency = this.basketService.getCurrency();
            this.filters.category = this.category;
            this.currentOrder = "Best Seller";
            this.filters.order = 'best';
            this.getFiltersByCategoryNameID(routeParams.categoryNameID);
          } else {

            this.filters.pagingdata = pagingData;
            this.productService.filter(this.filters)
              .subscribe(data => {
                this.products = data.products;
                this.amountPages = data.amountPages;
                this.amountResults = data.amountResults;
                this.filters.lastroute = this.page;
              });
          }
        }
      }




    });

    this.globals.refreshFilterAction = function (route, country, productService) {
      location.reload();
      // var urlArray = route.url.split("/");
      // this.page = urlArray[1];
      // var filters = new Filters;
      // this.types = null;
      // var pagingData: any = { amount: '12', page: '1'}
      // filters.brands = [];
      // filters.category = urlArray[1];
      // filters.subcategories = [];
      // filters.regions = [];
      // filters.pagingdata = pagingData;
      // filters.countries = [];
      // filters.tasteprofiles = [];
      // filters.intensities = [];
      // filters.prices = [];
      // filters.grapes = [];

      // filters.currentcurrency = country == 'cur' ? 'ANG' : '$';
      // this.currentOrder = "Best Seller";
      // filters.order = 'best';

      // productService.filter(filters)
      //   .subscribe(data => {
      //     this.products = data.products;
      //     this.amountPages = data.amountPages;
      //     this.amountResults = data.amountResults;
      //     filters.lastroute = this.page;
      //   });
    };
  }

  setOrder(order, name) {
    this.currentOrder = name;
    this.filters.order = order;
    if (this.currentPage == '1') {
      this.productService.filter(this.filters)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          this.filters.lastroute = this.page;
        });
    } else {
      var link = this.category + '/1/12';

      if (this.routeID) {
        link += '/' + this.routeID;
      }
      this.router.navigate([link]);
    }
  }

  toggleFilter() {
    this.globals._sideFilter = !this.globals._sideFilter;
    this.globals._backDrop = !this.globals._backDrop;
  }

  setValue(data): void {
    this.selectPageForm.setValue({
      pageNumber: data
    });
  }

  removeFilters() {
    this.filters.brands = [];
    this.filters.subcategories = [];
    this.filters.regions = [];
    this.filters.countries = [];
    this.filters.tasteprofiles = [];
    this.filters.prices = [];
    this.filters.grapes = [];
    this.filters.intensities = [];

    this.productService.filter(this.filters)
      .subscribe(data => {
        this.products = data.products;
        this.amountPages = data.amountPages;
        this.amountResults = data.amountResults;
        this.filters.lastroute = this.page;
      });

    if (this.grapes && this.grapes.length) {
      this.grapes.forEach(function (g: any) {
        g.active = false;
      });
    }

    if (this.subcategories && this.subcategories.length) {
      this.subcategories.forEach(function (f: any) {
        f.active = false;
      });
    }

    if (this.brands && this.brands.length) {
      this.brands.forEach(function (b: any) {
        b.active = false;
      });
    }

    if (this.regions && this.regions.length) {
      this.regions.forEach(function (r: any) {
        r.active = false;
      });
    }

    if (this.countries && this.countries.length) {
      this.countries.forEach(function (c: any) {
        c.active = false;
      });
    }

    if (this.intensities && this.intensities.length) {
      this.intensities.forEach(function (i: any) {
        i.active = false;
      });
    }

    if (this.tasteprofiles && this.tasteprofiles.length) {
      this.tasteprofiles.forEach(function (t: any) {
        t.active = false;
      });
    }

    this.prices.forEach(function (p: any) {
      p.active = false;
    });

  }

  checkFilter(type, filter) {
    var self = this;
    switch (type) {
      case 'subcategories':
        this.subcategories.forEach(function (f: any) {
          if (filter.subcategoryID === f.subcategoryID) {
            f.active = true;
          }
        });
        self.filters.subcategories.push(filter.subcategoryID);
        break;
      case 'brands':
        this.brands.forEach(function (b: any) {
          if (filter.brandID === b.brandID) {
            b.active = true;
          }
        });
        self.filters.brands.push(filter.brandID);
        break;
      case 'regions':
        this.regions.forEach(function (r: any) {
          if (filter.regionID === r.regionID) {
            r.active = true;
          }
        });
        self.filters.regions.push(filter.regionID);
        break;
      case 'countries':
        this.countries.forEach(function (c: any) {
          if (filter.countryID === c.countryID) {
            c.active = true;
          }
        });
        self.filters.countries.push(filter.countryID);
        break;
      case 'grapes':
        this.grapes.forEach(function (g: any) {
          if (filter.grapeID === g.grapeID) {
            g.active = true;
          }
        });
        self.filters.grapes.push(filter.grapeID);
        break;
      case 'tasteprofiles':
        this.tasteprofiles.forEach(function (t: any) {
          if (filter.tasteprofileID === t.tasteprofileID) {
            t.active = true;
          }
        });
        self.filters.tasteprofiles.push(filter.tasteprofileID);
        break;
      case 'intensities':
        this.intensities.forEach(function (i: any) {
          if (filter.intensityID === i.intensityID) {
            i.active = true;
          }
        });
        self.filters.intensities.push(filter.intensityID);
        break;
      case 'prices':
        this.prices.forEach(function (p: any) {
          if (filter.priceID === p.priceID) {
            p.active = true;
          }
        });
        self.filters.prices = [filter.data];
        break;
    }

    this.productService.filter(this.filters)
      .subscribe(data => {
        this.products = data.products;
        this.amountPages = data.amountPages;
        this.amountResults = data.amountResults;
        this.filters.lastroute = this.page;
        var dropdownValue = (this.amountPagingBtns <= this.currentPage && this.currentPage != this.amountPages ? this.currentPage : '');
        this.setValue(dropdownValue);
      });

  }

  setFilter(type, filter) {

    if (!this.filters[type]) {
      this.filters[type] = [];
    }

    switch (type) {
      case 'brands':
        if (filter.active) {
          this.removeItem(type, filter.brandID);
        } else {
          this.filters.brands.push(filter.brandID);
        }
        break;
      case 'colors':
        if (filter.active) {
          this.removeItem(type, filter.colorID);
        } else {
          this.filters.colors.push(filter.colorID);
        }
        break;
      case 'countries':
        if (filter.active) {
          this.removeItem(type, filter.countryID);
        } else {
          this.filters.countries.push(filter.countryID);
        }
        break;
      case 'regions':
        if (filter.active) {
          this.removeItem(type, filter.regionID);
        } else {
          this.filters.regions.push(filter.regionID);
        }
        break;
      case 'intensities':
        if (filter.active) {
          this.removeItem(type, filter.intensityID);
        } else {
          this.filters.intensities.push(filter.intensityID);
        }
        break;
      case 'subcategories':
        if (filter.active) {
          this.removeItem(type, filter.subcategoryID);
        } else {
          this.filters.subcategories.push(filter.subcategoryID);
        }
        break;
      case 'tasteprofiles':
        if (filter.active) {
          this.removeItem(type, filter.tasteprofileID);
        } else {
          this.filters.tasteprofiles.push(filter.tasteprofileID);
        }
        break;
      case 'grapes':
        if (filter.active) {
          this.removeItem(type, filter.grapeID);
        } else {
          this.filters.grapes.push(filter.grapeID);
        }
        break;
      case 'prices':
        let priceRange = [];
        let currentRange = null;
        const keys = Object.keys(this.prices);

        for (var i = 0; i < keys.length; i++) {
          let item = this.prices[keys[i]];
          let price = item.data;
          let isActive = item.priceID === filter.priceID ? !item.active : item.active;

          if (isActive) {
            if (currentRange) {
              if (currentRange[1] === price[0]) {
                currentRange[1] = price[1];
                continue;
              }
            }

            currentRange = [price[0], price[1]];
            priceRange.push(currentRange)

          }
        }

        this.filters.prices = priceRange;
        break;
    }

    filter.active = filter.active ? false : true;
    this.filters.currentcurrency = this.basketService.getCurrency();

    if (this.currentPage == '1') {
      this.productService.filter(this.filters)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          this.filters.lastroute = this.page;
        });
    } else {
      var link = this.category + '/1/12';

      if (this.routeID) {
        link += '/' + this.routeID;
      }

      this.router.navigate([link]);
    }

    this.globals.productFilter = this.filters;
  }

  counter(i: number) {
    let x: any = false;
    if (this.amountPages > this.amountPagingBtns) {
      x = new Array(this.amountPagingBtns);
    } else {
      x = new Array(i);
    }
    return x;
  }

  removeItem(type, item) {
    var self = this;

    self.filters[type].forEach(function (bp, key) {
      if (bp == item) {
        self.filters[type].splice(key, 1);
      }
    });
  }

  getFiltersByCategoryNameID(categoryNameID) {
    this.filtersService.getByNameID(categoryNameID)
      .subscribe(data => {
        this.brands = data.brands;
        this.countries = data.countries;
        this.colors = data.colors;
        this.regions = data.regions;
        this.intensities = data.intensities;
        this.subcategories = data.subcategories;
        this.tasteprofiles = data.tasteprofiles;
        this.grapes = data.grapes;
        this.prices = data.prices;
        this.checkFilter(this.types, this.fil);
      });

  }

  getProductsByCategoryNameID(categoryNameID, pagingData: Object) {
    this.productService.getProductsByCategoryNameID(categoryNameID, pagingData)
      .subscribe(data => {
        this.products = data.products;
        this.amountPages = data.amountPages;
        this.amountResults = data.amountResults;
      });
  }

  getSubCategoriesByCategoryNameID(data) {
    this.categoryService.getSubcategoryByCategoryNameID(data)
      .subscribe(data => {
        // this.subCategories = data;
      });
  }

  previousPage(page, amountPages, amountPerPage) {
    window.scrollTo(0, 0);
    var link = '/' + page + '/' + amountPages + '/' + amountPerPage;
    if (this.routeID) {
      link += '/' + this.routeID;
    }
    this.setValue('');

    this.router.navigate([link]);
  }

  paginationDropDownRage(n: number, startFrom: number): number[] {
    return [...Array(n).keys()].map(i => i + startFrom);
  }

  navigateTo(page, amountPerPage) {
    var currentPage = this.selectPageForm.value.pageNumber;
    if (currentPage) {
      var link = '/' + page + '/' + currentPage + '/' + amountPerPage;
      if (this.routeID) {
        link += '/' + this.routeID;
      }
      this.router.navigate([link]);
    } else {
      this.setValue(this.currentPage);
    }
  }

  nextPage(page, amountPages, amountPerPage) {
    window.scrollTo(0, 0);
    var link = '/' + page + '/' + amountPages + '/' + amountPerPage;
    if (this.routeID) {
      link += '/' + this.routeID;
    }
    this.router.navigate([link]);
  }
}
