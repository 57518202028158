<form [formGroup]="selectPageForm">
 
<div *ngIf="category" class="wrapper main overflow">
 
  <div style="overflow: hidden; margin-bottom: 30px;"> 


    <div class="filterBtn show-on-mobile" (click)="toggleFilter()">
      Filter &nbsp; <i class="fas fa-chevron-right"></i>
    </div>

    <div class="dropdown show dropdown-">
      <a class="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        {{currentOrder}}
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a (click)="setOrder('best', 'Best Seller')" class="dropdown-item">Best Seller</a>
        <a (click)="setOrder('nameA', 'Name A - Z')" class="dropdown-item">Name A - Z</a>
        <a (click)="setOrder('nameZ', 'Name Z - A')" class="dropdown-item">Name Z - A</a>
        <a (click)="setOrder('priceA', 'Price Ascending')" class="dropdown-item">Price Ascending</a>
        <a (click)="setOrder('priceD', 'Price Descending')" class="dropdown-item">Price Descending</a>
        <a *ngIf="category != 'deals'" (click)="setOrder('deals', 'Deals')" class="dropdown-item">Deals</a>
      </div>
    </div>
    <div class="category-title pagination-disable-mobile">
      <div>{{category}}</div>
    </div>
    <!-- pagination --> 
    
      <div class="pagination- pagination-disable-mobile" style="overflow: hidden; height: 40px; margin-bottom: 50px;">
        <div *ngIf="amountPages > 1" class="pagination-wrapper">
          <span *ngFor='let in of counter(amountPages);let i = index; let last = last'>
            <span class="span" (click)="previousPage(page, i+1,amountPerPage)"
              [ngClass]="{'selectedPage': currentPage == (i+1), 'last' : last}">{{i+1}}</span>
          </span>
          <span *ngIf="amountPagingBtns < amountPages && selectPageForm.value.pageNumber > amountPagingBtns"
            class="span">
            ...
          </span>

          <span *ngIf="amountPagingBtns < amountPages" class="drop-down drop-down-on">
            <select [ngClass]="{'select-on' : amountPagingBtns <= currentPage && currentPage != amountPages}"
              class="select-on" formControlName="pageNumber"
              style="padding: 0; color:#000; border: 0; width: 40px; height: 40px;"
              (change)="navigateTo(page,amountPerPage)">
              <option value="">...
              </option>
              <option [value]="i"
                *ngFor="let i of paginationDropDownRage((amountPages - amountPagingBtns), amountPagingBtns)">{{i}}
              </option>
            </select>

          </span>

          <span class="span" (click)="nextPage(page, amountPages, amountPerPage)"
            routerLink="/{{page}}/{{amountPages}}/{{amountPerPage}}"
            [ngClass]="{'selectedPage': currentPage == amountPages}">{{amountPages}}</span>
        </div>
      </div>
  
    <!-- pagination -->
  </div>
  
  <div class="row-">
    <div [ngClass]="{'show-filters' : globals._sideFilter}" class="col-left">
      <div class="filter-header show-on-mobile">
        <div style="margin-left: auto; padding: 0 18px;"><i (click)="toggleFilter()" style="font-size: 30px; cursor: pointer; color:#fff; line-height: 56px;" class="fal fa-times"></i> </div>
      </div>     
      <div class="left-nav" [ngClass]="{'page-coffee' : category === 'coffee'}">
        <div class="nespresso-logo" *ngIf="category === 'coffee'">
          <img src="../../../assets/logos/nespresso.png" width="200" />
        </div>
        <div class="show-on-mobile" style="text-transform: uppercase; line-height: 50px; padding-bottom: 15px; font-size: 20px; font-weight: 700;">
          FILTER {{category}}
        </div>

        <div class="filter subcategory-filter">
          <div class="filter-title">FILTERS</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container set-flex">
              <div (click)="setFilter(item.type, item)" class="filtered-items" *ngFor="let item of globals.productFilter.activeFilters">
                {{item.name}}&nbsp;&nbsp;<i class="fal fa-times"></i>
              </div>
              <!-- <div class="filter-items" *ngFor="let item of prices">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('prices', price)" class="item-checkbox">
                    <i *ngIf="price.active" class="fas fa-check"></i>
                  </div>
                </div> 
                <div class="item-name">{{price.name}}</div>
              </div> -->
            </div>         
          </div>
        </div>

       

        <div *ngIf="subcategories" class="filter subcategory-filter">
          <div class="filter-title">Type</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let sub of subcategories">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('subcategories', sub)" class="item-checkbox">
                    <i *ngIf="sub.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div *ngIf="category == 'wine' ; else elseBlock" class="item-name">{{sub.name}}</div>
                <ng-template #elseBlock>
                  <div class="item-name">{{sub.name}}</div>
                </ng-template>
              </div>
            </div>           
          </div>
        </div>
        
        <!-- <div *ngIf="variants" class="filter subcategory-filter">
          <div class="filter-title">Variant</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let var of variants">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('variants', var)" class="item-checkbox">
                    <i *ngIf="sub.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{var.name}}</div>        
              </div>
            </div>           
          </div>
        </div> -->
 
        <div *ngIf="prices && category !='water' " class="filter subcategory-filter">
          <div class="filter-title">Price in {{basketService.getCurrency()}}</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let price of prices">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('prices', price)" class="item-checkbox">
                    <i *ngIf="price.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{price.name}}</div>
              </div>
            </div>         
          </div>
        </div>

        <div *ngIf="grapes && category =='wine'" class="filter grapes-filter">
          <div class="filter-title">Grapes</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container scroll-container">
              <div class="filter-items" *ngFor="let grape of grapes">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('grapes', grape)" class="item-checkbox">
                    <i *ngIf="grape.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{grape.name}}</div>
              </div>
            </div>
            
          </div>
        </div>

        <div *ngIf="tasteprofiles" class="filter subcategory-filter">
          <div class="filter-title">Taste Profile</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let profile of tasteprofiles">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('tasteprofiles', profile)" class="item-checkbox">
                    <i *ngIf="profile.active" class="fas fa-check"></i></div>
                </div>
                <div [ngClass]="{'item-font-small' : category === 'wine'}" class="item-name">{{profile.name}}</div>
                <div [ngStyle]="{'background-color': profile.color}"
                  style="width:12px; height: 12px; margin-top: 9px; float: right;">&nbsp;</div>
              </div>
            </div>          
          </div>
        </div>

        <div *ngIf="countries && category == 'wine'" class="filter countries-filter">
          <div class="filter-title">Country</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let country of countries">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('countries', country)" class="item-checkbox">
                    <i *ngIf="country.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{country.name}}</div>
              </div>
            </div>       
          </div>
        </div>

        <!-- <div *ngIf="regions" class="filter regions-filter">
          <div class="filter-title">Region</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let region of regions">
              <div class="item-checkbox-container">
                <div (click)="setFilter('regions', region)" class="item-checkbox"><i *ngIf="region.active"
                    style="font-size: 12px; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i></div>
              </div>
              <div class="item-name">{{region.name}}</div>
            </div>
          </div>
        </div> -->

        <div *ngIf="brands && category == 'water' || brands && category == 'beers'" class="filter brands-filter">
          <div class="filter-title">Brands</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let brand of brands">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('brands', brand)" class="item-checkbox">
                    <i *ngIf="brand.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{brand.name}}</div>
              </div>
            </div>         
          </div>
        </div>



        <!-- <div *ngIf="colors" class="filter colors-filter">
          <div class="filter-title">Colors</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let color of colors">
              <div class="item-checkbox-container">
                <div (click)="setFilter('colors', color)" class="item-checkbox"><i *ngIf="color.active"
                    style="font-size: 12px; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i></div>
              </div>
              <div class="item-name">{{color.name}}</div>
            </div>
          </div>
        </div> -->

        <div *ngIf="intensities" class="filter intensity-filter">
          <div class="filter-title">Intensity</div>
          <div class="filter-items-wrapper">
            <div class="filter-items-container">
              <div class="filter-items" *ngFor="let intensity of intensities">
                <div class="item-checkbox-container">
                  <div (click)="setFilter('intensities', intensity)" class="item-checkbox">
                    <i *ngIf="intensity.active" class="fas fa-check"></i>
                  </div>
                </div>
                <div class="item-name">{{intensity.name}}</div>
              </div>
            </div>          
          </div>
        </div>
      </div>





      <!-- <div *ngIf="subCategories" class="filter countries-filter">
        <div class="filter-title">Category</div>
        <div class="filter-items-wrapper">
          <div class="filter-items" *ngFor="let subCategory of subCategories">
            <div class="item-checkbox-container">
              <div class="item-checkbox">&nbsp;</div>
            </div>
            <div class="item-name">{{subCategory.name}} </div>
          </div>
        </div>
      </div> -->

      <div (click)="removeFilters()" class="remove-filter-wrapper">
        <div>Remove all filters</div>
        <div class="remove-filter-close-container"><i class="fas fa-times"></i></div>
      </div>
      <div style="height: 50px;">
        &nbsp;
      </div>
    </div>
    <div class="col-right">
      <app-product-list-h [products]="products">Loading</app-product-list-h>
      <!-- pagination -->
      
        <div class="pagination-" style="overflow: hidden; height: 40px; margin-bottom: 50px;">
          <div *ngIf="amountPages > 1" class="pagination-wrapper">
            <span *ngFor='let in of counter(amountPages);let i = index; let last = last'>
              <span class="span" (click)="previousPage(page, i+1,amountPerPage)"
                [ngClass]="{'selectedPage': currentPage == (i+1), 'last' : last}">{{i+1}}</span>
            </span>
            <span *ngIf="amountPagingBtns < amountPages && selectPageForm.value.pageNumber > amountPagingBtns"
              class="span">
              ...
            </span>

            <span *ngIf="amountPagingBtns < amountPages" class="drop-down drop-down-on">
              <select [ngClass]="{'select-on' : amountPagingBtns <= currentPage && currentPage != amountPages}"
                class="select-on" formControlName="pageNumber"
                style="padding: 0; color:#000; border: 0; width: 40px; height: 40px;"
                (change)="navigateTo(page,amountPerPage)">
                <option value="">...
                </option>
                <option [value]="i"
                  *ngFor="let i of paginationDropDownRage((amountPages - amountPagingBtns), amountPagingBtns)">{{i}}
                </option>
              </select>

            </span>

            <span class="span" (click)="nextPage(page, amountPages, amountPerPage)"
              routerLink="/{{page}}/{{amountPages}}/{{amountPerPage}}"
              [ngClass]="{'selectedPage': currentPage == amountPages}">{{amountPages}}</span>
          </div>
        </div>
      
      <!-- pagination -->
    </div>
  </div>
</div>
</form>