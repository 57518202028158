import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-barcode-block',
  templateUrl: './barcode-block.component.html',
  styleUrls: ['./barcode-block.component.scss']
})
export class BarcodeBlockComponent implements OnInit {
  @ViewChild('barcode', { static: true }) barcodeElement: ElementRef;
  @Input() barcodeValue: string;

  constructor() { }

  ngOnInit(): void {
    this.generateBarcode();
  }

  generateBarcode() {
    if (this.barcodeValue) {
      window['JsBarcode'](this.barcodeElement.nativeElement, this.barcodeValue, {
        format: 'CODE128',
        displayValue: true,
        fontSize: 18
      });
    }
  }
  
}
