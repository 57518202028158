
import { Component, OnInit, Input } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() state: boolean = false;

  public navBtns: any = [
    { name: 'Home', selected: false, icon: '<i class="fal fa-home"></i>', route: 'home', base: 'wine', border: 'border-cy-green' },
    { name: 'Wines', selected: false, icon: '<i class="fal fa-wine-glass-alt"></i>', route: 'wine/1/12', base: 'wine', border: 'border-cy-green' },
    { name: 'Spirits', selected: false, icon: '<i class="fal fa-cocktail"></i>', route: 'spirits/1/12', base: 'spirits', border: 'border-aq-blue' },
    { name: 'Beers', selected: false, route: 'beers/1/12', icon: '<i class="fal fa-beer"></i>', base: 'beers', border: 'border-drk-yellow' },
    { name: 'Water & Beverages', selected: false, route: 'water/1/12', icon: '<i class="fal fa-glass"></i>', base: 'water', border: 'border-abr' },
    { name: 'Coffee', selected: false, icon: '<i class="fal fa-mug-hot"></i>', route: 'coffee/1/12', base: 'coffee', border: 'border-rar-pink' },    
    { name: 'Heets', selected: false, icon: '<i class="fal fa-smoking"></i>', route: 'heets/1/12', base: 'heets', border: 'border-rar-pink' },
    { name: 'Gifts', selected: false, icon: '<i class="fal fa-gift"></i>', route: 'gifts/1/12', base: 'gifts', border: 'border-rar-pink' }
  ]

  constructor(
    public globals: Globals,
    private router: Router,
    public basketService: BasketService
  ) { }

  ngOnInit(): void {
  }

  navigateTo(object) {
    this.navBtns.forEach(function (p: any) {
      p.selected = false;
    });

    // object.selected = true;
    this.router.navigateByUrl(object.route);   
  }

  toggleNav(){
    this.globals._navState = !this.globals._navState;
    this.globals._backDrop = !this.globals._backDrop;
  }

}
