import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Basket } from '../models/basket';
import { Order } from '../models/order';
import { Globals } from '../globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }
 
    createOrder(basket:Basket, formdata: any, country : any):any{
      console.log(`${this.globals.serverlink}createTransaction.php`);
      return this.http.post(`${this.globals.serverlink}createTransaction.php`, {basket, formdata, country});
    }
 
    getByUniqueID(uniqueID:number): Observable<Order> {
      return this.http.post<Order>(`${this.globals.serverlink}getOrder.php`, {uniqueID});
    }

    contactNespresso(data:any): any {
      return this.http.post(`${this.globals.serverlink}contactnespresso.php`, data);
    }
 
    
}
