import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeitemproductService } from 'src/app/services/homeitemproduct.service';


@Component({
  selector: 'app-homeitemproduct',
  templateUrl: './homeitemproduct.component.html',
  styleUrls: ['./homeitemproduct.component.scss']
})
export class HomeitemproductComponent implements OnInit {
  public homeItems: any;

  constructor(
    public homeItemProductService: HomeitemproductService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.id) {
        this.getHomeItemProductsByID(routeParams.id);      
      }
    });
   
  } 

  getHomeItemProductsByID(id) {
    this.homeItemProductService.getHomeItemProductsByID(id)
      .subscribe(data => {
        this.homeItems = data.data;
      });
  }


}
