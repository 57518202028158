import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../../globals';

// Imported Services
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CountryService } from '../../services/country.service';
import { BrandService } from '../../services/brand.service';
import { TypeService } from '../../services/type.service';
import { ColorService } from '../../services/color.service';
import { RegionService } from '../../services/region.service';

// Imported Models
import { Product } from 'src/app/models/product';
import { Color } from 'src/app/models/color';
import { Type } from 'src/app/models/type';
import { Region } from 'src/app/models/region';
import { Country } from 'src/app/models/country';
import { Brand } from 'src/app/models/brand';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category';
import { TasteprofileService } from 'src/app/services/tasteprofile.service';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { ButtonService } from 'src/app/services/button.service';
import { GrapesService } from 'src/app/services/grapes.service';
import { Grape } from 'src/app/models/grape';

@Component({
  selector: 'app-checkproduct',
  templateUrl: './checkproduct.component.html',
  styleUrls: ['./checkproduct.component.scss']
})
export class CheckproductComponent implements OnInit {
  public orderID: string = null;
  public product: any;
  public productForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public countries: Country[];
  public brands: Brand[];
  public types: Type[];
  public regions: Region[];
  public categories: Category[];
  public colors: Color[];
  public tasteprofiles: Tasteprofile[];
  public pin: any = null;
  public buttonnames: any;
  public buttonactions: any;
  public grapes: Grape[];
  public addedGrapes : any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public productService: ProductService,
    public globals: Globals,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private brandService: BrandService,
    private typeService: TypeService,
    private regionService: RegionService,
    private colorService: ColorService,
    private categoryService: CategoryService,
    private tasteprofileService: TasteprofileService,
    private buttonService: ButtonService,
    private grapesService: GrapesService
  ) { }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      name: [''],
      basePrice: [''],
      basePriceBon: [''],
      dealPriceCur: [''],
      dealPriceBon: [''],
      info: [''],
      pin: [''],
      foodPairing: [''],
      aroma: [''],
      taste: [''],
      grape: [''],
      litrage: [''],
      year: [''],
      deal: [''],
      countryID: [],
      categoryID: [''],
      ranking: [''],
      publishCur: [''],
      publishBon: [''],
      brandID: [''],
      typeID: [''],
      serve: [''],
      tasteprofileID: [''],
      regionID: [''],
      ingredients: [''],
      active: [''],
      alcoholPercentage: [''],
      colorID: [''],
      grapeID: [''],
      buttonNameID: [''],
      buttonActionID:['']
    });

    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.code) {
        this.getProductByCode(routeParams.code, 'cur');
        this.getCountries();
        this.getBrands();
        this.getTypes();
        this.getRegions();
        this.getColors();
        this.getCategories();
        this.getTasteprofiles();
        this.getButtonNames();
        this.getButtonActions();
        this.getGrapes();
      }
    });


  };

  getCategories() {
    this.categoryService.getAll()
      .subscribe(data => {
        this.categories = data;
      });
  }

  getGrapes() {
    this.grapesService.getAll()
      .subscribe(data => {
        this.grapes = data;
      });
  }

  setValue(data: Product): void {
    this.productForm.setValue({
      name: data.name,
      basePrice: data.basePrice,
      basePriceBon: data.basePriceBon,
      dealPriceCur: data.dealPriceCur,
      dealPriceBon: data.dealPriceBon,
      info: data.info,
      foodPairing: data.foodPairing,
      aroma: data.aroma,
      grape: data.grape,
      taste: data.taste,
      pin: '',
      litrage: data.litrage,
      year: data.year,
      deal: data.deal,
      countryID: data.countryID,
      categoryID: data.categoryID,
      ranking: data.ranking,
      publishCur: data.publishCur,
      publishBon: data.publishBon,
      brandID: data.brandID,
      typeID: data.typeID,
      serve: data.serve,
      tasteprofileID: data.tasteprofileID,
      regionID: data.regionID,
      ingredients: data.ingredients,
      active: data.active,
      alcoholPercentage: data.alcoholPercentage,
      colorID: data.colorID,
      buttonNameID: data.buttonNameID,
      buttonActionID: data.buttonActionID,
      grapeID: ""
    });
  }

 

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {
      this.productForm.value.productID = this.product.productID;
      this.productForm.value.grapes = this.addedGrapes;
      var formdata = this.productForm.value;

      if(this.productForm.value.categoryID != '2'){
        this.addedGrapes = [];
      }

 

      this.productService.updateProduct(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;                
        });
    }

  }

  addGrapes(){
    var index = this.productForm.value.grapeID;
    var grape = this.grapes[index];
    this.addedGrapes.push(grape);
 
  }

  removeGrapes(index){
    this.addedGrapes.splice(index, 1);
  }

  getButtonNames() {
    this.buttonService.getButtonNames()
      .subscribe(data => {
        this.buttonnames = data;
      });
  }

  getButtonActions() {
    this.buttonService.getButtonActions()
      .subscribe(data => {
        this.buttonactions = data;
      });
  }

  getCountries() {
    this.countryService.getAll()
      .subscribe(data => {
        this.countries = data;
      });
  }

  getColors() {
    this.colorService.getAll()
      .subscribe(data => {
        this.colors = data;
      });
  }

  getTasteprofiles() {
    this.tasteprofileService.getAll()
      .subscribe(data => {
        this.tasteprofiles = data;
      });
  }

  getBrands() {
    this.brandService.getAll()
      .subscribe(data => {
        this.brands = data;
      });
  }

  getTypes() {
    this.typeService.getAll()
      .subscribe(data => {
        this.types = data;
      });
  }

  getRegions() {
    this.regionService.getAll()
      .subscribe(data => {
        this.regions = data;
      });
  }


  getProductByCode(code: number, country: string) {
    this.productService.getByCode(code, country)
      .subscribe(data => {
        this.product = data;
        this.setValue(data);
        if(data.grapes.length){
          this.addedGrapes = data.grapes;
        }
      });
  }

} 
