<div *ngIf="!completed">
    <form [formGroup]="nespressoForm">
        <div class="wrapper main overflow">
            <div class="category-title">Nespresso Proffesional</div>
            <div class="row-">
                <div>
                    <div style="margin-bottom: 40px;">
                        <span>First Name</span><br />
                        <input formControlName="firstname" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Last Name</span><br />
                        <input formControlName="lastname" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Are you an existing customer?</span><br />
                        <select formControlName="existingcustomer" class="form-control">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Company Name</span><br />
                        <input formControlName="companyname" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Company Type</span><br />
                        <select formControlName="companytype" class="form-control">
                            <option value="Office">Office</option>
                            <option value="Restaurant or Cafe">Restaurant or Cafe</option>
                            <option value="Premium Food Service">Premium Food Service</option>
                            <option value="Resort">Resort</option>
                            <option value="Hotel">Hotel</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Phone</span><br />
                        <input formControlName="phone" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Email</span><br />
                        <input formControlName="email" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Help us advise you</span><br />
                        <textarea style="height: 100px;" formControlName="description" class="form-control"></textarea>
                    </div>
                    <div style="height: 30px;">
                        <div *ngIf="updated">
                            Succesfully Created
                        </div>
                    </div>
                    <div style="padding:0px 0 50px 0px ;">
                        <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                            <span *ngIf="!loading">Send</span>
                            <span *ngIf="loading">
                                Sending Information&nbsp;&nbsp;<img height="10"
                                    src="../../../assets/spinners/three-dots.svg" />
                            </span>
                        </div>
                    </div>
                    <div style="height: 50px;">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="completed">
    <div class="wrapper main overflow">
        <div class="category-title">YOUR MESSAGE HAS BEEN SENT</div>
        <div class="row-">
            Thank you for contacting Bottles online. We will be in touch with you shortly. <br /><br />
            We would like to wish you a wonderful day!
        </div>
    </div>
</div>