import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-checkdisactivated',
  templateUrl: './checkdisactivated.component.html',
  styleUrls: ['./checkdisactivated.component.scss']
})
export class CheckdisactivatedComponent implements OnInit {
  products: any;

  constructor(
    public productService: ProductService,
  ) { }

  ngOnInit(): void {
    this.getDisactivatedProducts();
  }


  getDisactivatedProducts() {
    this.productService.getProducts()
      .subscribe(data => {
        this.products = data;
      });
  }


}
