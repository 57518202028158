import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getCountries(productID:number): any {
    return this.http.post(`${this.globals.serverlink}getProductCountries.php`, {productID});
  }

  getByID(productID:number, country: string): Observable<Product> {  
    return this.http.post<Product>(`${this.globals.serverlink}getProductByID.php`, {productID, country});
  }

  getByCode(code:number, country: string): Observable<Product> {  
    return this.http.post<Product>(`${this.globals.serverlink}getProductByCode.php`, {code, country});
  }
  
  getProductsByCategoryNameID(categoryNameID:number, pagingData:Object): any {
    return this.http.post(`${this.globals.serverlink}getProductsByCategoryNameID.php`, {categoryNameID , pagingData});
  }

  getDeals(amount:number, country: string): any {
    return this.http.post(`${this.globals.serverlink}getDeals.php`, {amount, country});
  }


  getProducts(): any {
    return this.http.post(`${this.globals.serverlink}getProducts.php`, {});
  }

  checkProducts(): any {
    return this.http.post(`${this.globals.serverlink}checkProducts.php`, {});
  }

  getTopProducts(categoryNameID: number, amount: number): any {
    return this.http.post(`${this.globals.serverlink}getTopProducts.php`, {categoryNameID, amount});
  }

  updateProduct(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}updateProduct.php`, {formdata});
  }

  addProduct(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}addProduct.php`, {formdata});
  }



  getProductPrice(product: Product){
    var value = 0;
    if(this.globals.country === "cur"){
      value = product.basePrice;
    }

    if(this.globals.country === "bon"){
      value = product.basePriceBon;
    }

    return value;
  }

  getDealPrice(product: Product){
    var value = 0;
    if(this.globals.country === "cur"){
      value = product.dealPriceCur;
    }

    if(this.globals.country === "bon"){
      value = product.dealPriceBon;
    }

    return value;
  }

  getBestProducts(categoryNameID): any {
    return this.http.post(`${this.globals.serverlink}getBestProducts.php`, {categoryNameID});
  }

  filter(array): any {
    return this.http.post(`${this.globals.serverlink}filterCategory.php`, {array});
  }

}
