<div *ngIf="category" class="wrapper main overflow">
  <div class="row">
    <div class="col">
      <div style="background-color: black; color:#fff; padding: 20px 30px;">
        <div style="text-align: center; padding-bottom:40px;" class="intensity-filter">
          <img src="../../../assets/logos/nespresso.png" width="200" />
        </div>

        <div *ngIf="subcategories" class="filter subcategory-filter">
          <div class="filter-title">Type</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let sub of subcategories">
              <div class="item-checkbox-container">
                <div (click)="setFilter('subcategories', sub)" class="item-checkbox"><i *ngIf="sub.active"
                    style="font-size: 12px; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i></div>
              </div>
              <div *ngIf="category == 'wine' ; else elseBlock" class="item-name">{{sub.name}}</div>
              <ng-template #elseBlock>
                <div class="item-name">{{sub.name}}</div>
              </ng-template>            
            </div>
          </div> 
        </div>

        <!-- <div *ngIf="subcategories" class="filter subcategory-filter">
          <div class="filter-title">Price in {{basketService.getCurrency()}}</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let price of prices">
              <div class="item-checkbox-container">
                <div (click)="setFilter('prices', price)" class="item-checkbox"><i *ngIf="price.selected"
                    style="font-size: 12px; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i></div>
              </div>
              <div class="item-name">{{price.name}}</div>           
            </div>
          </div> 
        </div>
   -->

        <div *ngIf="tasteprofiles" class="filter subcategory-filter">
          <div class="filter-title">Flavors</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let profile of tasteprofiles">
              <div class="item-checkbox-container">
                <div (click)="setFilter('tasteprofiles', profile)" class="item-checkbox"><i *ngIf="profile.active"
                    style="font-size: 12px; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i></div>
              </div>
              <div class="item-name">{{profile.name}}</div>
            </div>
          </div>
        </div>

        <div *ngIf="intensities" class="filter intensity-filter">
          <div class="filter-title">Intensity</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let intensity of intensities">
              <div class="item-checkbox-container">
                <div (click)="setFilter('intensities', intensity)" class="item-checkbox"><i *ngIf="intensity.active"
                    style="font-size: 12px; color:#fff; margin-left: 4px; margin-top: 4px; position: absolute;"
                    class="fas fa-check"></i>
                </div>
              </div>
              <div class="intensity-container">
                <div *ngFor="let intens of array(intensity.name)" class="intensity-block">
                  &nbsp;
                </div>
              </div>
              <div class="item-name">{{intensity.name}}</div>
            </div>
          </div>
        </div>

      </div>






      <!-- <div *ngIf="subCategories" class="filter countries-filter">
          <div class="filter-title">Category</div>
          <div class="filter-items-wrapper">
            <div class="filter-items" *ngFor="let subCategory of subCategories">
              <div class="item-checkbox-container">
                <div class="item-checkbox">&nbsp;</div>
              </div>
              <div class="item-name">{{subCategory.name}} </div>
            </div>
          </div>
        </div> -->

    </div>
    <div class="col-9">
      <div style="overflow: hidden;">
        <div *ngIf="amountPages > 1" class="pagination-wrapper">
          <span *ngFor='let in of counter(amountPages);let i = index; let last = last'>
            <span class="span" routerLink="/{{page}}/{{i+1}}/{{amountPerPage}}"
              [ngClass]="{'selectedPage': currentPage == (i+1), 'last' : last}">{{i+1}}</span>
          </span>
          <span *ngIf="amountPagingBtns < amountPages" class="span">
            ...
          </span>
          <span class="span" routerLink="/{{page}}/{{amountPages}}/{{amountPerPage}}"
            [ngClass]="{'selectedPage': currentPage == amountPages}">{{amountPages}}</span>
        </div>
      </div>
      <app-product-list-h [products]="products">Loading</app-product-list-h>
      <div style="overflow: hidden;">
        <div *ngIf="amountPages > 1" class="pagination-wrapper">
          <span *ngFor='let in of counter(amountPages);let i = index; let last = last'>
            <span class="span" routerLink="/{{page}}/{{i+1}}/{{amountPerPage}}"
              [ngClass]="{'selectedPage': currentPage == (i+1), 'last' : last}">{{i+1}}</span>
          </span>
          <span *ngIf="amountPagingBtns < amountPages" class="span">
            ...
          </span>
          <span class="span" routerLink="/{{page}}/{{amountPages}}/{{amountPerPage}}"
            [ngClass]="{'selectedPage': currentPage == amountPages}">{{amountPages}}</span>
        </div>
      </div>
    </div>
  </div>
</div>