<div style="height:200px;">
    &nbsp;
</div>
<div class="container">
    <div style="padding: 20px 0;">
        <input type="text" [(ngModel)]="searchText" placeholder="Search" (input)="filterData()" placeholder="Search" class="form-control" />
    </div>
    <div class="row">
        <div class="col col-md-4 col-6" *ngFor="let item of filteredData" style="text-align: center;">
            <div class="p-3">
                <div style="text-align: left; padding-bottom: 20px; text-align: center; height: 250px;">
                    <img style="height: 250px;" src="../../../assets/products/{{item.code}}B.png" /><br />
                </div>
                <div class="pt-3 pb-3">
                    Name: {{item.name}}
                </div>
                <div style="text-align: center;">
                    <app-barcode-block *ngIf="item.bransimexId" barcodeValue="{{item.bransimexId}}"></app-barcode-block>
                </div>
            </div>
        </div>
    </div>
</div>