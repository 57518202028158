import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {
  products: any;
  searchText: string = '';
  filteredData: any[];

  constructor(
    public productService: ProductService
  ) { }

  ngOnInit(): void {
    this.getDeals();
  }

  getDeals() {
    this.productService.checkProducts()
      .subscribe(data => {
        this.products = data;
        this.filteredData = data;
      });
  }

  filterData() {
    if(!this.products)
      return;

    if(!this.searchText){
      this.filteredData = this.products;
      return;
    }
    
    this.filteredData = this.products.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }

}
