import { Component, OnInit } from '@angular/core';
import { HomeitemService } from 'src/app/services/homeitem.service';

@Component({
  selector: 'app-homepageitems',
  templateUrl: './homepageitems.component.html',
  styleUrls: ['./homepageitems.component.scss']
})
export class HomepageitemsComponent implements OnInit {
  public homeItems: any;

  constructor(
    public homeItemService: HomeitemService
  ) { }

  ngOnInit(): void {
    this.getHomeItems();
  } 

  getHomeItems() {
    this.homeItemService.getHomeItems()
      .subscribe(data => {
        this.homeItems = data;
      });
  }


}
