import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegionService } from '../../services/region.service';


@Component({
  selector: 'app-addregion',
  templateUrl: './addregion.component.html',
  styleUrls: ['./addregion.component.scss']
})
export class AddregionComponent implements OnInit {

  public regionForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;

  constructor(
    public regionService: RegionService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void { 
    this.regionForm = this.formBuilder.group({
      name: [''],
      pin: ['']
    });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.regionForm.value;

      this.regionService.addRegion(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
        });
    }

  }

}
