<div *ngIf="product" class="wrapper main overflow">
    <div class="product-title">{{product.name}}</div>
    <div class="row-">
        <div class="col-right">
            <div class="row">
                <div style="text-align: center;" class="col col-img">
                    <img class="product-bottle" src="../../../assets/products/{{product.code}}B.png" />
                </div>
                <div class="col col-info">
                    <div class="product-info-wrapper">
                        <div *ngIf="product.buttonNameID != '1' && product.buttonNameID !== '11'">
                            <div class="product-price-disable" *ngIf="product.deal == 1">
                                {{basketService.getCurrency()}} {{productService.getProductPrice(product)}}
                            </div>
                            <div *ngIf="product.deal != 1" class="product-price">
                                {{basketService.getCurrency()}} {{productService.getProductPrice(product)}}
                            </div>
                            <div *ngIf="product.deal == 1" class="product-price product-price-deal">
                                {{productService.getDealPrice(product)}}
                            </div>
                        </div>


                        <div *ngIf="product.buttonNameID == '1' || product.buttonNameID == '11'" class="product-price">
                            Product
                        </div>

                        <div class="product-info">
                            <div *ngIf="product.brand && product.brandID != 0" class="row">
                                <div class="col-4 product-info-label">
                                    Brand
                                </div>
                                <div class="col-8">
                                    {{product.brand.name}}
                                </div>
                            </div>
                            <div *ngIf="product.variant && product.variantID != 0" class="row">
                                <div class="col-4 product-info-label">
                                    Variant
                                </div>
                                <div class="col-8">
                                    {{product.variant.name}}
                                </div>
                            </div>
                            <div *ngIf="product.color && product.colorID != 0" class="row">
                                <div class="col-4 product-info-label">
                                    Color
                                </div>
                                <div class="col-8">
                                    {{product.color.name}}
                                </div>
                            </div>
                            <div *ngIf="product.serve" class="row">
                                <div class="col-4 product-info-label">
                                    Serve at
                                </div>
                                <div class="col-8">
                                    {{product.serve}}
                                </div>
                            </div>
                            <div *ngIf="product.litrage && product.litrage != '0'" class="row">
                                <div class="col-4 product-info-label">
                                    Litrage
                                </div>
                                <div class="col-8">
                                    {{product.litrage}}
                                </div>
                            </div>
                            <div *ngIf="product.country && product.countryID != 0" class="row">
                                <div class="col-4 product-info-label">
                                    Country
                                </div>
                                <div class="col-8">
                                    <span (click)="setFilter('countries', product.country, product.countryID)"
                                        class="item-hover">{{product.country.name}}</span>
                                </div>
                            </div>
                            <div *ngIf="product.region && product.regionID != 0" class="row">
                                <div class="col-4 product-info-label">
                                    Region
                                </div>
                                <div class="col-8">
                                    {{product.region.name}}
                                </div>
                            </div>
                            <div *ngIf="product.year != '0000'" class="row">
                                <div class="col-4 product-info-label">
                                    Year
                                </div>
                                <div class="col-8">
                                    {{product.year}}
                                </div>
                            </div>
                            <!-- <div (click)="add(product, 1)" class="product-add-btn">
                                <div class="text">Add Product</div>
                                <div class="icon"><i class="fal fa-plus"></i></div>
                            </div> -->
                            <div *ngIf="product.buttonActionID !== '2' && product.buttonActionID !== '4' && product.buttonNameID !== '11'"
                                style="margin-top: 20px;">
                                <form [formGroup]="quantityForm">
                                    <div style="display: flex;">
                                        <div>
                                            <label class="sr-only" for="inlineFormInputGroup">Quantity</label>
                                            <div style="height: 50px; border-radius: 0; width: 150px;"
                                                class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div
                                                        style="position: absolute; width: 45px; color:#495057; text-align: right; padding-right: 7px; margin-top: 3px; font-size: 10px;">
                                                        {{getBasketAmount()}}
                                                    </div>
                                                    <div class="input-group-text"><i class="fas fa-shopping-basket"></i>

                                                    </div>
                                                </div>
                                                <input formControlName="quantity"
                                                    style="height: 50px; z-index: 9; padding-top: 20px;" type="number"
                                                    class="form-control quantity-input" id="inlineFormInputGroup"
                                                    placeholder="">
                                                <div
                                                    style="position: absolute; z-index: 10; margin-top: 3px; margin-left: 55px; font-size: 12px;">
                                                    Quantity
                                                </div>
                                            </div>
                                        </div>
                                        <div (click)="addProduct()"
                                            style="height: 50px; padding: 0 15px;  font-size: 14px; cursor: pointer; line-height: 50px; display: flex; margin-left: 15px; color: #fff; background-color: #449c9c; border-radius: 0; width: 80px;">
                                            <div>
                                                ADD
                                            </div>
                                            <div style="margin-left: auto;">
                                                <i class="far fa-plus"></i>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>

                            <div *ngIf="product.buttonActionID == '2' && product.buttonNameID !== '11'">
                                <div style="margin-top: 23px;">
                                    <form [formGroup]="quantityForm">
                                        <label class="sr-only" for="inlineFormInputGroup">Quantity</label>
                                        <div style="height: 50px; border-radius: 0; width: 150px;"
                                            class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text"><i class="fas fa-shopping-basket"></i>
                                                </div>
                                            </div>
                                            <div style="height: 50px; z-index: 9; padding-top: 20px;"
                                                class="form-control quantity-input">
                                                {{getBasketAmount()}}
                                            </div>
                                            <div
                                                style="position: absolute; z-index: 10; margin-top: 3px; margin-left: 55px; font-size: 12px;">
                                                Quantity
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <ng-template #popContent>
                                    <div style="overflow: hidden; width: 200px;">
                                        <div *ngFor="let amount of amountCoffees"
                                            style="float: left; padding: 10px 0; cursor: pointer; text-align: center; width: 50px;">
                                            <span class="amount-prod-box"
                                                (click)="add(product, amount.name)">{{amount.name}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                                <button style="border: 0;" type="button" class="product-add-btn"
                                    [ngbPopover]="popContent">
                                    Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;<i class="fal fa-plus"></i>
                                </button>
                            </div>
                            <div *ngIf="product.buttonActionID == '4'">
                                <button routerLink="/nespressoprofessional" style="border: 0;" type="button"
                                    class="product-add-btn">
                                    Contact Us
                                </button>
                            </div>
                        </div>
                        <!-- -->
                        <div class="product-info" *ngIf="stock && (stock.inventoryBottlesStaMaria != '-' || stock.inventoryBottlesCaracasbaai != '-')">
                            <div _ngcontent-gpc-c107="" class="product-price" style="margin-bottom: 20px;"> Availability </div>
                            <div class="row">
                                <div class="col-4 product-info-label">
                                    Sta. Maria
                                </div>
                                <div class="col-8">
                                    <i *ngIf="stock.inventoryBottlesStaMaria == '0'" style="color: #999;" class="fas fa-check-circle"></i>
                                    <i *ngIf="stock.inventoryBottlesStaMaria != '0'" style="color: #32ba7c" class="fas fa-check-circle"></i>  
                                    <span>&nbsp;{{ stock.inventoryBottlesStaMaria == '0' ? 'Out of stock': 'In stock'}}</span>                                  
                                    <!-- {{stock.inventoryBottlesStaMaria}} -->
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4 product-info-label">
                                    Caracasbaaiweg
                                </div>
                                <div class="col-8">
                                    <i *ngIf="stock.inventoryBottlesCaracasbaai == '0'" style="color: #999;" class="fas fa-check-circle"></i>
                                    <i *ngIf="stock.inventoryBottlesCaracasbaai != '0'" style="color: #32ba7c" class="fas fa-check-circle"></i>  
                                    <span>&nbsp;{{ stock.inventoryBottlesCaracasbaai == '0' ? 'Out of stock': 'In stock'}}</span> 
                                    <!-- {{stock.inventoryBottlesCaracasbaai}} -->
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-left">
            <div style="margin-bottom: 40px;" *ngIf="product.info">
                <span style="font-weight: 600;">Product Info</span><br />
                <span [innerHTML]="product.info"></span>
            </div>
            <div *ngIf="product.grapes && product.grapes.length != 0" style="margin-bottom: 40px;">
                <span style="font-weight: 600;">Grape</span><br />
                <span class="item-hover" (click)="setFilter('grapes', grape, grape.grapeID)"
                    *ngFor="let grape of product.grapes; let first = first;">
                    <span *ngIf="!first">, </span>{{grape.name}}</span>
            </div>
            <div *ngIf="product.aroma" style="margin-bottom: 40px;">
                <span style="font-weight: 600;">Aroma</span><br />
                {{product.aroma}}
            </div>
            <div style="margin-bottom: 40px;" *ngIf="product.taste">
                <span style="font-weight: 600;">Taste</span><br />
                {{product.taste}}
            </div>
            <div *ngIf="product.foodPairing">
                <span style="font-weight: 600;">Food Pairing</span><br />
                {{product.foodPairing}}
            </div>
        </div>


    </div>
</div>