import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../models/brand';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class BrandService {
  
  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getBrandByCategoryNameID(categoryNameID:number): Observable<Brand[]> {
    return this.http.post<Brand[]>(`${this.globals.serverlink}getBrandByCategoryNameID.php`, {categoryNameID});
  }

  getAll(): Observable<Brand[]> {
    return this.http.post<Brand[]>(`${this.globals.serverlink}getBrands.php`, {});
  }

  addBrand(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}addBrand.php`, {formdata});
  }

}
