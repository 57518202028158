<div class="wrapper main">
    <div style="margin-bottom: 100px;" *ngFor="let product of products | keyvalue">
        <div style="padding-bottom: 20px;">
            {{product.key}}
        </div>
        <div style="overflow: hidden;">
            <div *ngFor="let item of product.value" class="home-item-block">
                <div [ngStyle]="{'background-color' : item.color}" style="position: absolute; z-index: -10; width: 180px; height: 250px;">
                    &nbsp;
                </div>
                <div style="min-width: 50px; z-index: 100; text-align: center; padding-top: 40px; margin: 0 auto;">
                    <img class="grow" style="z-index: 100; " src="../../../assets/products/{{item.code}}B.png"
                        height="250" />
                </div>
                <div>
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</div>  

