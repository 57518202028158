import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';

@Component({
  selector: 'app-best',
  templateUrl: './best.component.html',
  styleUrls: ['./best.component.scss']
})
export class BestComponent implements OnInit {
  public products : Product[];

  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.getBestProducts('');
  }

  getBestProducts(categoryNameID) {
    this.productService.getBestProducts(categoryNameID)
      .subscribe(data => {
        this.products = data.products;
      });
  }

}
