<div style="height:200px;">
    &nbsp;
</div>
<div class="container">
    <div style="padding: 20px 0;">
        <input type="text" [(ngModel)]="searchText" placeholder="Search" (input)="filterData()" placeholder="Search" class="form-control" />
    </div>
</div>
<table width="1200" style="margin: 0 auto;">
    <tr *ngFor="let item of filteredData">
        <td width="260" align="center">
            <div style="text-align: right; padding-bottom: 20px;">
                <img src="../../../assets/products/{{item.code}}.jpg" height="200" /><br />

            </div>
        </td>
        <td width="280" align="center">
            <div style="text-align: left; padding-bottom: 20px;">
                <img src="../../../assets/products/{{item.code}}B.png" /><br />
            </div>
        </td>
        <td>
            <div>
                Name: {{item.name}}
            </div>
            <div>
                Code: {{item.code}}
            </div>
            <div>
                Baseprice Curacao: {{item.basePrice}}
            </div>
            <div>
                Baseprice Bonaire: {{item.basePriceBon}}
            </div>
            <div>
                Dealprice Curacao: {{item.dealPriceCur}}
            </div>
            <div>
                Dealprice Bonaire: {{item.dealPriceBonaire}}
            </div> 
            <div routerLink="/check/{{item.code}}" style="width: 200px; cursor:pointer; text-align: center; padding: 10px; color: #fff; background-color: black;">
                EDIT
            </div>
        </td>
    </tr>
</table>