import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class HomeitemproductService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }


  addHomeItemProduct(formdata: any): any {
    return this.http.post(`${this.globals.serverlink}addHomeItemProduct.php`, {formdata});
  }

  getHomeItemProducts(country): any {
    return this.http.post(`${this.globals.serverlink}getHomeItemProducts.php`, {country});
  }

  getHomeItemProductsByID(homeItemID): any {
    return this.http.post(`${this.globals.serverlink}getHomeItemProductsByID.php`, homeItemID);
  }

  getHomeItemProductByID(homeItemProductID): any {
    return this.http.post(`${this.globals.serverlink}getHomeItemProductByID.php`, homeItemProductID);
  }

 
}
