<div class="wrapper main overflow">
    <div class="category-title">Disactivated Products</div>
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                
                <th scope="col">Publish Cur</th>
                <th scope="col">Publish Bon</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">&nbsp;</th>
         
            </tr> 
        </thead>
        <tbody>
            <tr  *ngFor="let hi of products ; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{hi.code}}</td>
                <td>{{hi.name}}</td>
                <td>{{hi.publishCur === '1' ? 'Yes'  : 'No'}}</td>
                <td>{{hi.publishBon === '1' ? 'Yes': 'No'}}</td>
                <td>
                    &nbsp;
                </td>
                <td style="text-align: right;">
                    <button routerLink="/check/{{hi.code}}" type="button" class="btn btn-dark btn-sm">Edit</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div style="height: 200px;">
    &nbsp;
</div>