import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../models/category';
import { Country } from '../../models/country';
import { Brand } from '../../models/brand';
import { Product } from '../../models/product';
import { Color } from '../../models/color';
import { Region } from '../../models/region';
import { ProductService } from '../../services/product.service';
import { FiltersService } from '../../services/filters.service';
import { Filters } from '../../models/filters';
import { Intensity } from '../../models/intensity';
import { Globals } from '../../globals';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { BasketService } from '../../services/basket.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coffee',
  templateUrl: './coffee.component.html',
  styleUrls: ['./coffee.component.scss']
})
export class CoffeeComponent implements OnInit {
  public category: string = null;
  public countries: Country[];
  public subcategories: any;
  public brands: Brand[];
  public colors: Color[];
  public products: Product[];
  public filters: Filters = new Filters;
  public regions: Region[];
  public tasteprofiles: Tasteprofile[];
  public intensities: Intensity[];

  public prices: Object = [
    { name: '0 - 20', selected: false },
    { name: '20 - 40', selected: false },
    { name: '40 - 60', selected: false },
    { name: '60 - 80', selected: false },
    { name: '80 - 100', selected: false },
    { name: '100 +', selected: false }
  ]

  page: any = "";
  amountPages: any = false;
  currentPage: any = null;
  amountPerPage: any = false;
  amountResults: any = false;
  amountPagingBtns: number = 6;

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private productService: ProductService,
    private filtersService: FiltersService,
    public globals: Globals,
    public basketService: BasketService,
    private router: Router
  ) { }

  ngOnInit(): void {

    var self = this;
    this.globals.refreshFilterAction = function (updateCountry?: Country) {

      self.activatedRoute.params.subscribe(routeParams => {
        if (routeParams.categoryNameID && routeParams.page && routeParams.amount) {

          self.filters.currentcurrency = self.basketService.getCurrency();

          if (updateCountry) {
            self.filters.countries.push(updateCountry.countryID as any);
          }

          self.productService.filter(self.filters)
            .subscribe(data => {
              self.products = data.products;
              self.amountPages = data.amountPages;
              self.amountResults = data.amountResults;
            });
        }
      });
    };

    this.activatedRoute.params.subscribe(routeParams => {

      if (routeParams.page && routeParams.amount) {
        this.category = 'coffee';
        this.page = this.category;
        var pagingData: any = { amount: routeParams.amount, page: routeParams.page }
        this.currentPage = routeParams.page;
        this.amountPerPage = routeParams.amount;
        this.globals.currentPage = this.category + '/1/12';



        if (this.filters.lastroute != this.page) {
          this.getFiltersByCategoryNameID(this.category);
          this.filters = new Filters;
        }

        this.filters.currentcurrency = this.basketService.getCurrency();
        this.filters.category = this.category;
        this.filters.pagingdata = pagingData;

        self.productService.filter(this.filters)
          .subscribe(data => {
            this.products = data.products;
            this.amountPages = data.amountPages;
            this.amountResults = data.amountResults;
            this.filters.lastroute = this.page;
          });
      }
    });
  }

  // ngOnInit(): void {
  //   this.activatedRoute.params.subscribe(routeParams => {
  //     if (routeParams.page && routeParams.amount) {
  //       this.category = 'coffee';
  //       this.page = this.category; 
  //       var pagingData: any = { amount: routeParams.amount, page: routeParams.page }
  //       this.currentPage = routeParams.page;
  //       this.amountPerPage = routeParams.amount;
  //       this.globals.currentPage = this.category + '/1/12';


  //       this.getFiltersByCategoryNameID(this.category);
  //       this.getProductsByCategoryNameID(this.category, pagingData);
  //       this.filters = new Filters;
  //       this.filters.category = this.category;
  //       this.filters.brands = [];
  //       this.filters.subcategories = [];
  //       this.filters.countries = [];
  //       this.filters.colors = [];
  //       this.filters.regions = [];
  //       this.filters.intensities = [];
  //       this.filters.tasteprofiles = [];
  //     }
  //   });
  // } 

  array(n: string): any[] {
    let item = parseInt(n);
    return Array(item);
  }


  setFilter(type, filter) {

    if (!this.filters[type]) {
      this.filters[type] = [];
    }


    switch (type) {
      case 'intensities':
        if (filter.active) {
          this.removeItem(type, filter.intensityID);
        } else {
          this.filters.intensities.push(filter.intensityID);
        }
        break;
      case 'categories':
        if (filter.active) {
          this.removeItem(type, filter.categoryID);
        } else {
          this.filters.subcategories.push(filter.categoryID);
        }
        break;
      case 'tasteprofiles':
        if (filter.active) {
          this.removeItem(type, filter.tasteprofileID);
        } else {
          this.filters.tasteprofiles.push(filter.tasteprofileID);
        }
        break;
      case 'subcategories':
        if (filter.active) {
          this.removeItem(type, filter.subcategoryID);
        } else {
          this.filters.subcategories.push(filter.subcategoryID);
        }
        break;

    }

    filter.active = filter.active ? false : true;
    this.filters.currentcurrency = this.basketService.getCurrency();
   
    if (this.currentPage == '1') {
      this.productService.filter(this.filters)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          this.filters.lastroute = this.page;
        });
    } else {
      this.router.navigate([this.category + '/1/12']);
    }

  }

  counter(i: number) {
    let x: any = false;
    if (this.amountPages > this.amountPagingBtns) {
      x = new Array(this.amountPagingBtns);
    } else {
      x = new Array(i);
    }
    return x;
  }

  removeItem(type, item) {
    var self = this;

    self.filters[type].forEach(function (bp, key) {
      if (bp == item) {
        self.filters[type].splice(key, 1);
      }
    });
  }

  getFiltersByCategoryNameID(categoryNameID) {
    this.filtersService.getByNameID(categoryNameID)
      .subscribe(data => {
        this.brands = data.brands;
        this.countries = data.countries;
        this.colors = data.colors;
        this.regions = data.regions;
        this.intensities = data.intensities;
        this.subcategories = data.subcategories;
        this.tasteprofiles = data.tasteprofiles;
      });

  }

  // getProductsByCategoryNameID(categoryNameID, pagingData: Object) {
  //   this.productService.getProductsByCategoryNameID(categoryNameID, pagingData)
  //     .subscribe(data => {
  //       this.products = data.products;
  //       this.amountPages = data.amountPages;
  //       this.amountResults = data.amountResults;
  //     });
  // }

  getSubCategoriesByCategoryNameID(data) {
    this.categoryService.getSubcategoryByCategoryNameID(data)
      .subscribe(data => {
        // this.subCategories = data;
      });
  }
}
