import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';;
import { MainComponent } from './components/main/main.component';
import { MainnewComponent } from './components/mainnew/mainnew.component';
import { ProductComponent } from './components/product/product.component';
import { OrderComponent } from './components/order/order.component';
import { SearchComponent } from './components/search/search.component';
import { BestComponent } from './components/best/best.component';
import { CheckComponent } from './components/check/check.component';
import { AddproductComponent } from './components/addproduct/addproduct.component';
import { AddbrandComponent } from './components/addbrand/addbrand.component';
import { OrdercompletedComponent } from './components/ordercompleted/ordercompleted.component';
import { CheckproductComponent } from './components/checkproduct/checkproduct.component';
import { LoginComponent } from './components/login/login.component';
import { MydealsComponent } from './components/mydeals/mydeals.component';
import { AddregionComponent } from './components/addregion/addregion.component';
import { AddgrapeComponent } from './components/addgrape/addgrape.component';
import { NespressocontactComponent } from './components/nespressocontact/nespressocontact.component';
import { GiftscontactComponent } from './components/giftscontact/giftscontact.component';
import { AddhomeitemComponent } from './components/addhomeitem/addhomeitem.component';
import { EdithomeitemsComponent } from './components/edithomeitems/edithomeitems.component';
import { AddhomeitemproductComponent } from './components/addhomeitemproduct/addhomeitemproduct.component';
import { EdithomeitemproductComponent } from './components/edithomeitemproduct/edithomeitemproduct.component';
import { HomepageitemsComponent } from './components/homepageitems/homepageitems.component';
import { HomeitemproductComponent } from './components/homeitemproduct/homeitemproduct.component';
import { CheckdisactivatedComponent } from './components/checkdisactivated/checkdisactivated.component';
import { OrdercancelledComponent } from './components/ordercancelled/ordercancelled.component';
import { OrderfailedComponent } from './components/orderfailed/orderfailed.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { BarCodeComponent } from './components/barcode/barcode.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' }, 
  { path: 'home', component: HomeComponent },
  { path: 'home/:country', component: HomeComponent },
  { path: 'product/:productID', component: ProductComponent },  
  { path: 'order/:country', component: OrderComponent },
  { path: 'order/completed/:uniqueID', component: OrdercompletedComponent },
  { path: 'order/cancelled/:uniqueID', component: OrdercancelledComponent },
  { path: 'order/failed/:uniqueID', component: OrderfailedComponent },
  { path: 'login', component: LoginComponent },
  { path: 'mydeals', component: MydealsComponent }, 
  { path: 'nespressoprofessional', component: NespressocontactComponent },
  { path: 'search/:term', component: SearchComponent },
  { path: 'check', component: CheckComponent },
  { path: 'barcodes', component: BarCodeComponent },
  { path: 'addproduct', component: AddproductComponent },
  { path: 'customizegift', component: GiftscontactComponent },
  { path: 'addbrand', component: AddbrandComponent },
  { path: 'addregion', component: AddregionComponent },
  { path: 'addhomeitem', component: AddhomeitemComponent },
  { path: 'addhomeitemproduct', component: AddhomeitemproductComponent },
  { path: 'edithomeitemproduct/:id', component: EdithomeitemproductComponent },
  { path: 'addgrape', component: AddgrapeComponent },
  { path: 'check/:code', component: CheckproductComponent },
  { path: 'bestsellers', component: BestComponent },
  { path: 'homeitems', component: HomepageitemsComponent }, 
  { path: 'homeitems/:id', component: EdithomeitemsComponent }, 
  { path: 'homeitemproducts/:id', component: HomeitemproductComponent }, 
  { path: ':categoryNameID/:page/:amount', component: MainnewComponent },
  { path: ':categoryNameID/:page/:amount/:id', component: MainnewComponent },
  { path: 'checkdisactivatedproduct', component: CheckdisactivatedComponent },
  { path: 'gallery', component: GalleryComponent }   
];
  
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) 
export class AppRoutingModule { }
 