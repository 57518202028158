import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../models/product';
import { Globals } from '../../globals';
import { BasketService } from '../../services/basket.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-product-list-h',
  templateUrl: './product-list-h.component.html',
  styleUrls: ['./product-list-h.component.scss']
})
export class ProductListHComponent implements OnInit {
  @Input() products: Product[] = [];
  iteration = 10;

  public amountCoffees: Object = [
    { name: 0 },
    { name: 10 },
    { name: 20 },
    { name: 30 },
    { name: 40 },
    { name: 50 },
    { name: 60 },
    { name: 70 },
    { name: 80 },
    { name: 90 },
    { name: 100 },
    { name: 150 }
  ]

  constructor(
    public globals: Globals,
    public basketService: BasketService,
    public productService: ProductService
  ) { }

  array(n: string): any[] {
    let item = parseInt(n);
    return Array(item);
  }

  getGridAmount(iteration) { 
    let amount = 12;
    var gridValues = [];

    var i: number;
    for (i = 0; i < amount; i++) {
      var value =  { name: i * iteration };
      gridValues.push(value);
    }

    return gridValues;

  } 

  ngOnInit() {
    
  }

  add(product: any, amount: number) {    
    this.basketService.addProduct(product, amount);
  }

}
