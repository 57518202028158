<div class="category-section top-padding">
    <div class="category-title">
        Gallery
    </div>
    <div class="yt-wrapper">

        <div *ngIf="basketService.getCountry() == 'Bonaire';else NotBonaire" class="video-container">
            <iframe class="iframe" src="https://www.youtube.com/embed/4djbEHXDDdg" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
        <ng-template #NotBonaire>
            <div>
               
            </div>
        </ng-template>
    </div>



</div>