import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Product } from '../../models/product';
import { Globals } from '../../globals';
import { BasketService } from '../../services/basket.service';
import { OrderService } from '../../services/order.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  public orderForm: FormGroup;
  public basket: any = null;
  public loading: boolean = false;
  public _router: any;
  public _orderForm: any;


  constructor(
    public globals: Globals,
    private activatedRoute: ActivatedRoute,
    public basketService: BasketService,
    public orderService: OrderService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.globals.refreshFilterAction = function (route, country) {
      route.navigateByUrl('order/' + country);
    };

    this.orderForm = this.formBuilder.group({
      firstname: [null, [Validators.required, Validators.minLength(2)]],
      lastname: [null, [Validators.required, Validators.minLength(2)]],
      email: [null, [Validators.required, Validators.email]],
      telephone: [null, [Validators.required, Validators.minLength(6)]],
      address: [null, [Validators.required, Validators.minLength(3)]],
      ordertype: ['delivery'],
      remarks: [null],
      timeslot: ['Morning'],
      uptodate: ['Yes'],
      payOnline: ['No'],
      wrap: ['No'],
      deliverDate: [null, [Validators.required]],
      carplate: [null]
    });

    this.setFormValue(this.form);

    this.setOrderFormValidators();
    this.globals.currentPage = 'order';

    this.activatedRoute.params.subscribe(routeParams => {
      this.getBasketProducts();
      this.setOrderStatus();
    });
  }

  get firstname() { return this.orderForm.get('firstname'); }
  get lastname() { return this.orderForm.get('lastname'); }

  setFormData() {
    this.form = this.orderForm.value;
  }

  set form(form: FormGroup) {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem("orderform", JSON.stringify(form));
    }
  }

  get form(): FormGroup {
    if (typeof localStorage !== 'undefined') {
      var orderFormJson = localStorage.getItem("orderform");
      var orderForm = JSON.parse(orderFormJson);
      this._orderForm = orderForm;
      return orderForm;
    }

    return this._orderForm;
  }


  setOrderFormValidators() {
    const carplate = this.orderForm.get('carplate');

    this.orderForm.get('ordertype').valueChanges
      .subscribe(ordertype => {

        if (ordertype === 'delivery') {
          carplate.setValidators(null);
          carplate.setValue(null);
        } else {
          carplate.setValidators([Validators.required]);
        }

        carplate.updateValueAndValidity();
      });
  }



  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  isFieldValid(field: string) {
    return !this.orderForm.get(field).valid && this.orderForm.get(field).touched;
  }

  deleteProduct(basketProduct: any) {
    let orderType = this.orderForm.value.ordertype;
    this.basketService.deleteBasketItem(basketProduct, orderType);
    this.getBasketProducts();
  }

  getBasketProducts() {
    this.basket = this.basketService.getBasketProducts();
  }

  isCoffeeQuantityValid(quantity) {
    if (quantity % 10 === 0) {
      return true;
    }

    return false;
  }

  setValue(product, value) {
    var self = this;
    if (product.product.categoryID == 6) {
      if (self.isCoffeeQuantityValid(value)) {
        this.basketService.deleteBasketItem(product.product);
        value = Math.floor(value);
        this.basketService.addProduct(product.product, value);
        this.basketService.updateBasket(this.orderForm.value.ordertype);
        this.getBasketProducts();
      } else {
        alert('Please use multiples of 10 example: 10, 20, 30')
        this.getBasketProducts();
      }
    } else {
      if (value != '') {
        this.basketService.deleteBasketItem(product.product);
        value = Math.floor(value);
        this.basketService.addProduct(product.product, value);
        this.basketService.updateBasket(this.orderForm.value.ordertype);
        this.getBasketProducts();
      }
    }
  }


  add(product: any, amount: number) {
    amount = Math.floor(amount);

  }

  setOrderStatus() {
    let orderType = this.orderForm.value.ordertype;
    this.basketService.updateBasket(orderType);
  }

  navigateTo(href: string) {
    this.router.navigateByUrl(href);
  }


  setFormValue(data): void {
    if (data) {
      this.orderForm.setValue({
        firstname: data.firstname ? data.firstname : null,
        lastname: data.lastname ? data.lastname : null,
        telephone: data.telephone ? data.telephone : null,
        address: data.address ? data.address : null,
        carplate: data.carplate ? data.carplate : null,
        ordertype: data.ordertype ? data.ordertype : 'store',
        timeslot: data.timeslot ? data.timeslot : 'morning',
        email: data.email ? data.email : null,
        remarks: null,
        wrap: data.wrap ? data.wrap : 'No',
        uptodate: data.uptodate ? data.uptodate : 'No',
        deliverDate: null
        ,payOnline: 'No'
      });
    }

  }

  payOrder() {
    if (!this.orderForm.valid) {
      this.validateAllFormFields(this.orderForm);
      return false;
    }

    const items = JSON.stringify(this.minifyBasket());
  
    const formValue = encodeURI(JSON.stringify(this.orderForm.value));

    var country = this.globals.country;
 
    window.location.href = "https://b1ef-190-4-177-156.ngrok.io/BottlesServer/mollieTest.php?i=" + items + "&c=" + country + "&f=" + formValue;

  } 

  minifyBasket() {
    var basket = this.basketService.getBasket();

    var array: any = [];

    basket.basketProduct.forEach(function (bp, key) {

      array.push({ productID: bp.product.productID, amount: bp.amount });

    });

    return array;

  }

  submitOrder() {
    if (!this.orderForm.valid) {
      this.validateAllFormFields(this.orderForm);
      return false;
    }

    this.loading = true;
    if (this.loading) {
      var formdata = this.orderForm.value;
      var country = this.globals.country;

      var basket = this.basketService.getBasket();
      this.orderService.createOrder(basket, formdata, country)
        .subscribe(data => {
          if (data.valid) {
            this.navigateTo('/order/completed/' + data.transaction.uniqueID);
            this.globals.basket = null;
          } else {
            this.loading = false;
          }
        });
    }

  }

}
