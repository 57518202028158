import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CountryService } from '../../services/country.service';
import { BrandService } from '../../services/brand.service';
import { TypeService } from '../../services/type.service';
import { ColorService } from '../../services/color.service';
import { RegionService } from '../../services/region.service';
import { CategoryService } from '../../services/category.service';
import { SubcategoryService } from '../../services/subcategory.service';
import { TasteprofileService } from '../../services/tasteprofile.service';
import { ButtonService } from '../../services/button.service';
import { GrapesService } from '../../services/grapes.service';

// Imported Models
import { Product } from 'src/app/models/product';
import { Color } from 'src/app/models/color';
import { Type } from 'src/app/models/type';
import { Region } from 'src/app/models/region';
import { Country } from 'src/app/models/country';
import { Brand } from 'src/app/models/brand';
import { Category } from 'src/app/models/category';
import { Subcategory } from 'src/app/models/subcategory';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { Grape } from 'src/app/models/grape';



@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.scss']
})
export class AddproductComponent implements OnInit {
  public productForm: FormGroup;
  public orderID: string = null;
  public product: any;
  public loading: boolean = false;
  public updated: boolean = false;
  public countries: Country[];
  public brands: Brand[];
  public types: Type[];
  public regions: Region[];
  public grapes: Grape[];
  public categories: Category[];
  public subcategories: Subcategory[];
  public tasteprofiles: Tasteprofile[];
  public colors: Color[];
  public buttonnames: any;
  public buttonactions: any;
  public pin: any = null;
  public addedGrapes : any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public productService: ProductService,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private brandService: BrandService,
    private typeService: TypeService,
    private regionService: RegionService,
    private grapesService: GrapesService,
    private colorService: ColorService,
    private categoryService: CategoryService,
    private tasteprofileService: TasteprofileService,
    private subcategoryService: SubcategoryService,
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      name: [''],
      basePrice: ['0.00'],
      basePriceBon: ['0.00'],
      dealPriceCur: ['0.00'],
      dealPriceBon: ['0.00'],
      info: [''],
      pin: [''],
      foodPairing: [''],
      aroma: [''],
      taste: [''],
      grapeID: [''],
      litrage: [''],
      year: [''],
      deal: ['0'],
      code: [''],
      countryID: [],
      buttonNameID: [],
      buttonActionID: [],
      ranking: [''],
      publishCur: [''],
      publishBon: [''],
      categoryID: [''],
      subcategoryID: [''],
      tasteprofileID: [''],
      brandID: [''],
      typeID: [''],
      serve: [''],
      regionID: [],
      ingredients: [''],
      active: [''],
      alcoholPercentage: [''],
      colorID: [''],
      grape: ['']
    });
    this.getCountries();
    this.getCategories();
    this.getBrands();
    this.getTypes();
    this.getRegions();
    this.getColors();
    this.getTasteprofiles();
    this.getSubcategories();
    this.getButtonNames();
    this.getButtonActions();
    this.getGrapes();
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.productForm.value;
      formdata.grapes = this.addedGrapes;

      this.productService.addProduct(formdata)

        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
        },

          (error) => console.error(error)
        );

      this.loading = false;
    }

  }

  addGrapes(){
    var index = this.productForm.value.grapeID;
    var grape = this.grapes[index];
    this.addedGrapes.push(grape);
 
  }

  removeGrapes(index){
    this.addedGrapes.splice(index, 1);
  }

  getButtonNames() {
    this.buttonService.getButtonNames()
      .subscribe(data => {
        this.buttonnames = data;
      });
  }

  getButtonActions() {
    this.buttonService.getButtonActions()
      .subscribe(data => {
        this.buttonactions = data;
      });
  }

  getCountries() {
    this.countryService.getAll()
      .subscribe(data => {
        this.countries = data;
      });
  }

  getTasteprofiles() {
    this.tasteprofileService.getAll()
      .subscribe(data => {
        this.tasteprofiles = data;
      });
  }

  getRegions() {
    this.regionService.getAll()
      .subscribe(data => {
        this.regions = data;
      });
  }

  getCategories() {
    this.categoryService.getAll()
      .subscribe(data => {
        this.categories = data;
      });
  }

  getSubcategories() {
    this.subcategoryService.getAll()
      .subscribe(data => {
        this.subcategories = data;
      });
  }

  getColors() {
    this.colorService.getAll()
      .subscribe(data => {
        this.colors = data;
      });
  }

  getGrapes() {
    this.grapesService.getAll()
      .subscribe(data => {
        this.grapes = data;
      });
  }

  getBrands() {
    this.brandService.getAll()
      .subscribe(data => {
        this.brands = data;
      });
  }

  getTypes() {
    this.typeService.getAll()
      .subscribe(data => {
        this.types = data;
      });
  }

}
