<div class="wrapper main overflow">
    <div class="category-title">Home Item Products</div>
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Active</th>
                <th style="text-align: right;" scope="col"><i style="cursor: pointer;" class="fas fa-plus-circle" routerLink="/addhomeitemproduct"></i></th>
            </tr>
        </thead>
        <tbody>
            <tr routerLink="/edithomeitemproduct/{{hi.homeItemProductID}}" *ngFor="let hi of homeItems ; let i = index">
                <th scope="row">{{i+1}}</th>
                <td>{{hi.productCode}}</td>
                <td>{{hi.startDate}}</td>
                <td>{{hi.endDate}}</td>
                <td>{{hi.active === '1' ? "Yes" : 'No'}}</td>
                <td style="text-align: right;">
                    <button type="button" class="btn btn-dark btn-sm">Edit</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div style="height: 200px;">
    &nbsp;
</div>