import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getItems(form: string, country: string): Observable<Product[]> {
    return this.http.post<Product[]>(`${this.globals.serverlink}getSearchItems.php`, { form, country });
  }
}
