import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandService } from '../../services/brand.service';

// Imported Models
import { Brand } from 'src/app/models/brand';

@Component({
  selector: 'app-addbrand',
  templateUrl: './addbrand.component.html',
  styleUrls: ['./addbrand.component.scss']
})
export class AddbrandComponent implements OnInit {
  public brandForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;

  constructor(
    public brandService: BrandService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void { 
    this.brandForm = this.formBuilder.group({
      name: [''],
      pin: [''],
      position: ['']
    });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.brandForm.value;

      this.brandService.addBrand(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
        });
    }

  }


}
