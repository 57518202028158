<div class="wrapper nav-main desktop">
    <div class="navbarr">
        <a routerLink="home" class="home">Home</a>
        <div class="dropdownn">
            <button [ngClass]="{'btn-active' : link.selected}" *ngFor="let link of links" (click)="navigateTo(link)"
                (mouseover)="getFiltersByCategoryNameID(link.base)" class="dropbtn">
                <span [ngClass]="{'red-text' : link.name === 'Gifts'}">{{link.name}}</span>
            </button>
            <div [ngClass]="{'hideDropDown' : !dropdownContent}" class="dropdown-content">
                <div class="wrapper subnav-wrapper">
                    <div *ngIf="subcategories" class="subnav-col">
                        <div class="subnav-title">Type</div>
                        <div *ngFor="let sub of subcategories">
                            <span class="subnav-items"
                                (click)="setFilter('subcategories', sub, sub.subcategoryID)">{{sub.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="tasteprofiles && categoryNameID !== 'wine'" class="subnav-col">
                        <div class="subnav-title">Flavors</div>
                        <div class="subnav-items" *ngFor="let profile of tasteprofiles">
                            <span class="subnav-items"
                                (click)="setFilter('tasteprofiles', profile, profile.tasteprofileID)">{{profile.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="prices" class="subnav-col">
                        <div class="subnav-title">Prices in {{basketService.getCurrency()}}</div>
                        <div class="subnav-items" *ngFor="let price of prices">
                            <span (click)="setFilter('prices', price, price.priceID)"
                                class="subnav-items">{{price.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="brands && categoryNameID === 'beers' || brands && categoryNameID === 'water'"
                        class="subnav-col">
                        <div class="subnav-title">Brands</div>
                        <div class="subnav-items" *ngFor="let brand of brands">
                            <span class="subnav-items"
                                (click)="setFilter('brands', brand, brand.brandID)">{{brand.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="countries && categoryNameID === 'wine'" class="subnav-col">
                        <div class="subnav-title">Country</div>
                        <div class="subnav-items" *ngFor="let country of countries">
                            <span class="subnav-items"
                                (click)="setFilter('countries', country, country.countryID)">{{country.name}}</span>
                        </div>
                    </div>
                    <!-- <div *ngIf="regions" class="subnav-col">
                        <div class="subnav-title">Region</div>
                        <div class="subnav-items" (click)="setFilter('regions', region)" *ngFor="let region of regions">
                            {{region.name}}
                        </div>
                    </div> -->
                    <div *ngIf="intensities" class="subnav-col">
                        <div class="subnav-title">Intensity</div>
                        <div (click)="setFilter('intensities', intensity, intensity.intensityID)" class="subnav-items"
                            *ngFor="let intensity of intensities">
                            <span class="subnav-items">{{intensity.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="topProducts && categoryNameID == 'wine' || topProducts && categoryNameID == 'spirits'"
                        class="subnav-col">
                        <div class="subnav-title">Top 10 </div>
                        <div class="subnav-items" *ngFor="let top of topProducts">
                            <span class="subnav-items" routerLink="product/{{top.productID}}">{{top.name}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="wrapper subnav-wrapper">
                    <div style="width: 130px; cursor: pointer; font-size: 14px; border-radius: 3px; padding: 7px; background-color: #913837; color:#fff; text-align: center;">
                        See Wines
                    </div>
                </div> -->
            </div>
        </div>
        <a routerLink="mydeals" *ngIf="authenticationService && authenticationService.currentUserValue"
            style="font-weight: 700;" class="home">MY DEALS&nbsp;&nbsp;<i class="fas fa-chevron-right"></i></a>
        <a *ngIf="basketService.getCountry() == 'Bonaire'" routerLink="gallery" class="home">Gallery</a>
        <!-- <ng-template #elseBlock>
            <a routerLink="christmasdeals/1/12" style="font-weight: 700;" class="home">CHRISTMAS DEALS&nbsp;&nbsp;<i
                    class="fas fa-chevron-right"></i></a>
        </ng-template> -->

    </div>
</div>