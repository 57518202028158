import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Product } from '../../models/product';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public term: string;

  constructor(
    public globals: Globals,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.term) {
        this.term = routeParams.term;
        this.search(routeParams.term, this.globals.country);
      }
    });   
  } 

  search(keyword: string, country: string) { 
    if(keyword.length > 2){
      this.searchService.getItems(keyword, country)
      .subscribe(data => {
        this.globals.searchResults = data;
      });
    }   
  }

}
