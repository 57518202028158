import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../../services/order.service';


@Component({
  selector: 'app-giftscontact',
  templateUrl: './giftscontact.component.html',
  styleUrls: ['./giftscontact.component.scss']
})
export class GiftscontactComponent implements OnInit {

  public nespressoForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public completed: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0); 
    this.nespressoForm = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      existingcustomer: [''],
      companyname: [''],
      companytype: [''],
      phone: [''],
      email: [''],
      description: ['']
    });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    if (this.loading) {

      var formdata = this.nespressoForm.value;

      this.orderService.contactNespresso(formdata)
        .subscribe(data => {
          if(data.succeeded){
            this.completed = true;
            this.nespressoForm.reset();
          }

        });

      this.loading = false;
    }

  }

}
