import { Injectable } from "@angular/core";
import { Basket } from "./models/basket";
import { Product } from "./models/product";
import { Country } from "./models/country";
import { Filters } from "./models/filters";
import { Observable, Subject } from "rxjs";

@Injectable()
export class Globals {
  public _basket: Basket = null;

  private _country: string = "cur";
  public searchResults: Product[] = null;
  public currentPage: string = "";
  public refreshFilterAction: Function;
  public onPageLoadAction: Function = null;
  public _navState: boolean = false;
  public _backDrop: boolean = false;
  public _sideFilter: boolean = false;
  public _productFilter: any;
  private _vidSrc: string;

  refreshFilter(value?: any, value2?: any, value3?: any) {
    if (this.refreshFilterAction) {
      this.refreshFilterAction(value, value2, value3);
    }
  }

  host = window.location.protocol + "//" + window.location.hostname;

  serverlink: string = this.host + "/BottlesServer/";
  // serverlink: String = "http://localhost/BottlesServer/";
  //serverlink = 'https://www.bottles.online/BottlesServer/'
  //serverlink = 'https://dev.bottles.online/BottlesServer/'
  // serverlink:string = "/BottlesServer/";

  set productFilter(value: any) {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("productFilter", JSON.stringify(value));
    } else {
      this._productFilter = value;
    }
  }

  // get productFilter(): any {
  //     if (typeof localStorage !== 'undefined') {
  //         var productFilterJson = localStorage.getItem("productFilter");
  //         var productFilter = JSON.parse(productFilterJson);
  //         this._productFilter = productFilter;
  //         return productFilter;
  //     }

  //     return this._productFilter;
  // }

  get videoSrc(): any {
    return this._vidSrc;
  }

  set videoSrc(value: any) {
    this._vidSrc = value;
  }

  get productFilter(): any {
    if (typeof localStorage !== "undefined") {
      var productFilterJson = localStorage.getItem("productFilter");
      if (productFilterJson && productFilterJson != null) {
        this._productFilter = JSON.parse(productFilterJson);
      } else {
        this.initProductFilter();
      }

      //this._productFilter = productFilter;
      return this._productFilter;
    }

    return this._productFilter;
  }

  initProductFilter() {
    var productFilter = new Filters();
    productFilter.brands = [];
    productFilter.activeFilters = [];
    productFilter.subcategories = [];
    productFilter.regions = [];
    productFilter.countries = [];
    productFilter.tasteprofiles = [];
    productFilter.intensities = [];
    productFilter.prices = [];
    productFilter.grapes = [];
    productFilter.activeFilters = [];
    productFilter.order = "best";
    this.productFilter = productFilter;
  }

  clearProductFilter() {
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem("productFilter");
    }
  }

  set basket(value: Basket) {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("basket", JSON.stringify(value));
    } else {
      this._basket = value;
    }
  }

  get basket(): Basket {
    if (typeof localStorage !== "undefined") {
      var basketJson = localStorage.getItem("basket");
      var basket = JSON.parse(basketJson);
      this._basket = basket;
      return basket;
    }

    return this._basket;
  }

  set country(value) {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("country", value);
    } else {
      this._country = value;
    }
  }

  get country(): string {
    if (typeof localStorage !== "undefined") {
      var storageCountry = localStorage.getItem("country");

      if (storageCountry) {
        //return storageCountry;
        return storageCountry;
      }

      // localStorage.setItem("country", this._country)
    }

    return this._country || "cur";
  }
}
