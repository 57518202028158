import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Grape } from '../models/grape';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class GrapesService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  
    addGrape(formdata: any): any {
      return this.http.post(`${this.globals.serverlink}addGrape.php`, {formdata});
    }

    getAll(): Observable<Grape[]> {
      return this.http.post<Grape[]>(`${this.globals.serverlink}getGrapes.php`, {});
    }
  
}
