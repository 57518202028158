import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Globals } from './globals';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './components/main/main.component';
import { ProductComponent } from './components/product/product.component';
import { ProductListHComponent } from './components/product-list-h/product-list-h.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { OrderComponent } from './components/order/order.component';
import { CoffeeComponent } from './components/coffee/coffee.component';
import { SearchComponent } from './components/search/search.component';
import { BestComponent } from './components/best/best.component';
import { CheckComponent } from './components/check/check.component';
import { OrdercompletedComponent } from './components/ordercompleted/ordercompleted.component';
import { CheckproductComponent } from './components/checkproduct/checkproduct.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FullOverlayComponent } from './components/full-overlay/full-overlay.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainnewComponent } from './components/mainnew/mainnew.component';
import { AddproductComponent } from './components/addproduct/addproduct.component';
import { AddbrandComponent } from './components/addbrand/addbrand.component';
import { LoginComponent } from './components/login/login.component';
import { MydealsComponent } from './components/mydeals/mydeals.component';
import { AddregionComponent } from './components/addregion/addregion.component';
import { AddgrapeComponent } from './components/addgrape/addgrape.component';
import { NespressocontactComponent } from './components/nespressocontact/nespressocontact.component';
import { GiftscontactComponent } from './components/giftscontact/giftscontact.component';
import { AddhomeitemComponent } from './components/addhomeitem/addhomeitem.component';
import { AddhomeitemproductComponent } from './components/addhomeitemproduct/addhomeitemproduct.component';
import { HomepageitemsComponent } from './components/homepageitems/homepageitems.component';
import { EdithomeitemsComponent } from './components/edithomeitems/edithomeitems.component';
import { HomeitemproductComponent } from './components/homeitemproduct/homeitemproduct.component';
import { EdithomeitemproductComponent } from './components/edithomeitemproduct/edithomeitemproduct.component';
import { CheckdisactivatedComponent } from './components/checkdisactivated/checkdisactivated.component';
import { OrdercancelledComponent } from './components/ordercancelled/ordercancelled.component';
import { OrderfailedComponent } from './components/orderfailed/orderfailed.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { BarCodeComponent } from './components/barcode/barcode.component';
import { BarcodeBlockComponent } from './components/barcode-block/barcode-block.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    MainComponent,
    ProductComponent,
    ProductListHComponent,
    NavBarComponent,
    OrderComponent,
    CoffeeComponent,
    SearchComponent,
    BestComponent,
    CheckComponent,
    OrdercompletedComponent,
    CheckproductComponent,
    SideNavComponent,
    FullOverlayComponent,
    FooterComponent,
    MainnewComponent,
    AddproductComponent,
    AddbrandComponent,
    LoginComponent,
    MydealsComponent,
    AddregionComponent,
    AddgrapeComponent,
    NespressocontactComponent,
    GiftscontactComponent,
    AddhomeitemComponent,
    AddhomeitemproductComponent,
    HomepageitemsComponent,
    EdithomeitemsComponent,
    HomeitemproductComponent,
    EdithomeitemproductComponent,
    CheckdisactivatedComponent,
    OrdercancelledComponent,
    OrderfailedComponent,
    GalleryComponent,
    BarCodeComponent,
    BarcodeBlockComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Globals, 
    FormBuilder
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
