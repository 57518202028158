import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonService } from 'src/app/services/button.service';
import { HomeitemService } from 'src/app/services/homeitem.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeitemproductService } from 'src/app/services/homeitemproduct.service';

@Component({
  selector: 'app-edithomeitemproduct',
  templateUrl: './edithomeitemproduct.component.html',
  styleUrls: ['./edithomeitemproduct.component.scss']
})
export class EdithomeitemproductComponent implements OnInit {
  public addHomeItemProductForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;
  public homeItems: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public homeItemService: HomeitemService,
    public homeItemProductService: HomeitemproductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private buttonService: ButtonService
  ) { }

  ngOnInit(): void {
    this.addHomeItemProductForm = this.formBuilder.group({
      homeItemProductID: [''],
      homeItemID: [''],
      productCode: [''],
      active: [''],
      startDate: [''],
      endDate: [''],
      position: [''],
      redirectUrl: [''],
      pin: ['']
    });
    this.getHomeItems();
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.id) {
        this.getHomeItemProductByID(routeParams.id);

      }
    });
  }

  getHomeItems() {
    this.homeItemService.getHomeItems()
      .subscribe(data => {
        this.homeItems = data;
      });
  }

  getHomeItemProductByID(homeItemProductID) {
    this.homeItemProductService.getHomeItemProductByID(homeItemProductID)
      .subscribe(data => {
        this.setValue(data.data);
      });
  }

  setValue(data: any): void {
    this.addHomeItemProductForm.setValue({
      homeItemProductID: data.homeItemProductID,
      homeItemID: data.homeItemID,
      productCode: data.productCode,
      active: data.active,
      startDate: {
        "year": data.startYear,
        "month": data.startMonth,
        "day": data.startDay
      },
      endDate: {
        "year": data.endYear,
        "month": data.endMonth,
        "day": data.endDay
      },
      position: data.position,
      redirectUrl: '',
      pin: ''
    });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.addHomeItemProductForm.value;
      formdata.action = 'update';

      this.homeItemProductService.addHomeItemProduct(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;

          if (data.updated) {
            this.router.navigateByUrl('homeitemproducts/' + this.addHomeItemProductForm.value.homeItemID);
          }
        });
    }

  }

}
