<div class="footer-wrapper">
    <div class="footer">
        <div class="footer-content">
            <div class="footer-content-col f-site">
                <div class="footer-txt-title">SITE</div>
                <div class="footer-text" routerLink="/wine/1/12">Wines</div>
                <div class="footer-text" routerLink="/spirits/1/12">Spirits</div>
                <div class="footer-text" routerLink="/beers/1/12">Beers</div>
                <div class="footer-text" routerLink="/water/1/12">Water & Beverages</div>
                <div class="footer-text" routerLink="/coffee/1/12">Coffee</div>
                <div class="footer-text" routerLink="/deals/1/12">Deals</div>
            </div>
            
            <div class="footer-content-col f-follow">
                <div class="footer-txt-title">FOLLOW US</div>
                <div (click)="facebookURL()" class="footer-text"><i style="font-size: 16px;" class="fab fa-facebook"></i> Facebook</div>
                <div (click)="instagramURL()" class="footer-text"><i style="font-size: 16px;" class="fab fa-instagram"></i> Instagram</div>
            </div>
            <div *ngIf="globals.country == 'cur'" class="footer-content-col f-contact">
                <div class="footer-txt-title">CONTACT</div>
                <div>Bottles Cura&ccedil;ao</div>            
                <div>info@bottles.online</div>
                <!-- <div>&nbsp;</div>
                <div>+599 9 4621465</div>
                <div>Caracasbaaiweg #121</div>
                <div>+599 9 8884798</div>
                <div>Winston Churchillweg #112</div>
                <div>Willemstad, Cura&ccedil;ao</div> -->
            </div>
            <div *ngIf="globals.country == 'bon'" class="footer-content-col f-contact">
                <div class="footer-txt-title">CONTACT</div>
                <div>Bottles Bonaire</div>            
                <div>info@bottles.online</div>
                <div>+599 7179470</div>
                <div>Kaya Industria 43</div>
                <div>Kralendijk, Bonaire</div>
            </div>
            <div class="footer-content-col f-open">
                <div class="footer-txt-title">OPENING HOURS</div>
                <div>
                    Caracasbaaiweg #121
                </div>
                <div>
                    Monday to Thursday : 8AM - 6PM  
                </div>
                <div>
                    Friday and Saturday : 8AM - 7PM
                </div> 
                <div>+599 9 4621465</div>
                <div>&nbsp;</div>
                <div>
                    Winston Churchillweg #112
                </div>
                <div>
                    Monday to Thursday : 10AM - 6PM  
                </div>
                <div>
                    Friday and Saturday : 10AM - 7PM
                </div> 
                <div>+599 9 8884798</div>
                <div class="trustpilot-widget">
                    <div id="TA_linkingWidgetRedesign565" class="TA_linkingWidgetRedesign"><ul id="0v8k9z" class="TA_links vm0Zhi"><li id="CWJkaVyS7IXZ" class="4eLYW5WM"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g147278-d27662974-Reviews-Bottles-Willemstad_Curacao.html"><img src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg" alt="TripAdvisor"/></a></li></ul></div><script async src="https://www.jscache.com/wejs?wtype=linkingWidgetRedesign&amp;uniq=565&amp;locationId=27662974&amp;lang=en_US&amp;border=true&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
                </div>
            </div>
            <div class="footer-content-col f-mlist">
                <div class="footer-txt-title">UPCOMING DEALS & NEWS</div>
                <div style="display: flex; border: 1px solid #fff;">
                    <div>
                        <input placeholder="Enter your e-mail" style="height: 37px;  padding: 0 7px; width: 205px; border: 1px solid #fff;" />
                    </div>
                    <div style="width: 37px; cursor:pointer; color:#fff; line-height: 37px; font-size: 16px; text-align: center; margin-left: auto; height: 37px; background-color: #4b9d9e;">
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
