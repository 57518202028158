import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Product } from '../../models/product';
import { Country } from '../../models/country';
import { Globals } from '../../globals';
import { BasketService } from '../../services/basket.service';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from '../../models/filters';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('setCountryModal', { static: false }) setCountryModal: ElementRef;
  closeResult: any;
  public filters: Filters = new Filters;
  public searchForm: FormGroup;
  public selectedCountry: Country = { countryID: 2, name: 'test' }
  public sideNavState: boolean;



  constructor(
    public authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public globals: Globals,
    public basketService: BasketService,
    public productService: ProductService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    if (typeof localStorage !== 'undefined') {
      var storageCountry = localStorage.getItem('country');
      var productFilter = localStorage.getItem('productFilter');

      if (!storageCountry) {
        this.open(this.setCountryModal);
      }

      if(!productFilter){
        this.globals.initProductFilter();
      }

    
  }
   
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      keyword: ['']
    });


  }

  setFilter() {
    this.filters.order = "deals";
    this.globals.refreshFilter(this.filters);
  }

  toggleSideNav(){
    this.globals._navState = !this.globals._navState;
    this.globals._backDrop = !this.globals._backDrop;
  }

  logout() {
    this.authenticationService.logout();

    this.router.navigate(['/login']);
  }


  setCountry(country) {
    // this.globals.onPageLoadAction = function(component:any){

    //   component.productService.filter(component.filters)
    //     .subscribe(data => {
    //       component.products = data.products;
    //       component.amountPages = data.amountPages;
    //       component.amountResults = data.amountResults;
    //       component.filters.lastroute = component.page;
    //     });
    // }

    this.globals.country = country;
    this.basketService.updateBasket();
    this.modalService.dismissAll();
    // this.globals.refreshFilter(this.selectedCountry as any);
    this.globals.refreshFilter(this.router, this.globals.country, this.productService);
  }

  open(content: any) {
    this.modalService.open(content, { windowClass: 'dark-modal', ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }



  search() {
    var keyword = this.searchForm.value.keyword;
    if (keyword.length > 2) {
      this.router.navigate(['search/' + this.searchForm.value.keyword]);
    } else {

      this.router.navigate([this.globals.currentPage]);
    }
  }

  order() {
    if (this.basketService.getSubTotal() !== '0.00') {
      this.router.navigate(['/order/' + this.globals.country]);
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
