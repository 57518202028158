<form [formGroup]="searchForm">
    <div class="header mobile">
        <div class="mobile side-nav-toggle-icon">
            <i (click)="toggleSideNav()" class="fal fa-bars"></i>
        </div>
        <div (click)="order()" class="mobile-basket">
            <div [ngClass]="{'amount-basket-items-on' : basketService.getProductTotal() > 0}"
                class="amount-basket-items">
                {{basketService.getProductTotal()}}
            </div>
            <i class="fal fa-shopping-basket"></i>
        </div>
    </div>
    <div class="search-mobile mobile">
        <div class="search-icon"><i class="far fa-search"></i></div>
        <div class="search-input">
            <input (keyup)="search()" formControlName="keyword" placeholder="Search..." type="text" />
        </div>
    </div>
    <div class="header desktop">
        <div class="wrapper">
            <div class="row-">
                <div class="left-col">
                    <div class="botlles-slogan">
                        WINE & SPIRITS
                    </div>
                    <div class="logo">
                        <img src="../../../assets/logo.svg" height="90">
                    </div>
                </div>
                <div class="right-col">
                    <div class="top-nav-space">
                        Bottles Store&nbsp;&nbsp;<i class="far fa-chevron-right"></i>&nbsp;&nbsp;
                        <span (click)="open(setCountryModal)" class="set-cursor">{{basketService.getCountry()}}</span>
                        <span *ngIf="authenticationService && authenticationService.currentUserValue" (click)="logout()" class="set-cursor">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logout</span>
                    </div>
                    <div class="basket-search-wrapper">
                        <div [ngClass]="{'basket-active' : basketService.getSubTotal() !== '0.00'}" (click)="order()"
                            class="basket">
                            <div class="basket-icon"><i class="fal fa-shopping-basket"></i></div>
                            <div class="basket-amount">{{basketService.getCurrency()}} {{basketService.getSubTotal()}}
                            </div>
                        </div>
                        <div class="search">
                            <div class="search-icon"><i class="far fa-search"></i></div>
                            <div class="search-input">
                                <input (keyup)="search()" formControlName="keyword" placeholder="Search..."
                                    type="text" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<app-side-nav></app-side-nav>
<app-full-overlay></app-full-overlay>

<div class="navbar-container">
    <app-nav-bar></app-nav-bar>
</div>


<ng-template #setCountryModal let-modal>
    <div class="my-modal-content">
        <div class="row">
            <div style="padding: 20px 0" class="col">
                <div style="text-align:center; padding: 20px 0;">
                    <img src="../../../assets/logo-black-text.svg" width="150" />
                </div>
                <div class="enter-btn" (click)="setCountry('cur')">
                    Bottles Curaçao
                </div>
            </div>
            <div style="padding: 20px 0" class="col">
                <div style="text-align:center; padding: 20px 0;">
                    <img src="../../../assets/logo-black-text.svg" width="150" />
                </div>
                <div style="background-color: #913837;" class="enter-btn" (click)="setCountry('bon')">
                    Bottles Bonaire
                </div>
            </div>
        </div>
        <div style="text-align: center; padding: 15px 0; font-size: 14px;">By entering this website you confirm that
            you
            are 18+.</div>
    </div>
</ng-template>