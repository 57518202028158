import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../models/country';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }


  getAll(): Observable<Country[]> {
    return this.http.post<Country[]>(`${this.globals.serverlink}getCountries.php`, {});
  }

  getByCategoryNameID(getByCategoryNameID: number): Observable<Country[]> {
    return this.http.post<Country[]>(`${this.globals.serverlink}getCountriesByProductID.php`, { getByCategoryNameID });
  }
}
