import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandService } from '../../services/brand.service';
import { GrapesService } from '../../services/grapes.service';

@Component({
  selector: 'app-addgrape',
  templateUrl: './addgrape.component.html',
  styleUrls: ['./addgrape.component.scss']
})
export class AddgrapeComponent implements OnInit {
  public grapeForm: FormGroup;
  public loading: boolean = false;
  public updated: boolean = false;
  public pin: any = null;

  constructor(     
    private formBuilder: FormBuilder,
    private grapeService: GrapesService
  ) { }

  ngOnInit(): void { 
    this.grapeForm = this.formBuilder.group({
      name: [''],
      pin: [''],
      position: ['']
    });
  }

  submit() {
    this.loading = true;
    this.updated = false;
    this.pin = null;
    if (this.loading) {

      var formdata = this.grapeForm.value;

      this.grapeService.addGrape(formdata)
        .subscribe(data => {
          this.updated = data.updated;
          this.pin = data.pin;
          this.loading = false;
        });
    }

  }
}
