import { Component, OnInit } from '@angular/core';
import { CustomerproductService } from '../../services/customerproduct.service';

@Component({
  selector: 'app-mydeals',
  templateUrl: './mydeals.component.html',
  styleUrls: ['./mydeals.component.scss']
})
export class MydealsComponent implements OnInit {
  customerProducts: any;
 
  constructor(
    private customerProductService: CustomerproductService
  ) { }

  ngOnInit(): void {
    this.getCustomerDeals();
  }

  getCustomerDeals() {

    this.customerProductService.getCustomerProducts()
      .subscribe(data => {
       this.customerProducts = data.data
      });

  }

}
