<div *ngIf="!completed">
    <form [formGroup]="nespressoForm">
        <div class="wrapper main overflow">
            <div class="category-title">Create own package</div>
            <div class="row-">
                <div>
                    <div style="margin-bottom: 40px;"> 
                        Do you prefer something else? <br/>Please fill in your contact details below.<br /> We will contact you talk about your preferences to customize every package to your needs.
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>First Name</span><br />
                        <input formControlName="firstname" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Last Name</span><br />
                        <input formControlName="lastname" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Phone</span><br />
                        <input formControlName="phone" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Email</span><br />
                        <input formControlName="email" type="text" class="form-control" placeholder="">
                    </div>
                    <div style="margin-bottom: 40px;">
                        <span>Your Request</span><br />
                        <textarea style="height: 100px;" formControlName="description" class="form-control"></textarea>
                    </div>
                    <div style="height: 30px;">
                        <div *ngIf="updated">
                            Succesfully Created
                        </div>
                    </div>
                    <div style="padding:0px 0 50px 0px ;">
                        <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                            <span *ngIf="!loading">Send</span>
                            <span *ngIf="loading">
                                Sending Information&nbsp;&nbsp;<img height="10"
                                    src="../../../assets/spinners/three-dots.svg" />
                            </span>
                        </div>
                    </div>
                    <div style="height: 50px;">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="completed">
    <div class="wrapper main overflow">
        <div class="category-title">THANK YOU</div>
        <div class="row-">
            Thank you for contacting us. We will be in touch with you shortly. <br /><br />
            We would like to wish you a wonderful day!
        </div>
    </div>
</div>