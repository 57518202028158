import { BasketProduct } from './basketProduct';

export class Basket{
  basketProduct: BasketProduct[] = [];
  lastInteractionTime: number;
  subTotal: number = 0;
  subTotalDecimal: string = "0.00";
  totalDecimal: string = "0.00";
  currency: string = "ANG";
  total: number;
  deliveryCosts: number;
  minimumDeliveryCur: number = 200;
  minimumDeliveryBon: number = 100;
  deliveryCostsDecimal: string = "0.00";
  orderType : string;
  productTotal: number;
}
 