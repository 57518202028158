import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-ordercompleted',
  templateUrl: './ordercompleted.component.html',
  styleUrls: ['./ordercompleted.component.scss']
})
export class OrdercompletedComponent implements OnInit {
  public order: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    public orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.uniqueID) {     

        this.orderService.getByUniqueID(routeParams.uniqueID)
        .subscribe(data => {
          if (data.valid) {
            this.order = data;
            console.log(data);
          }
        });
      }
    });
  };
}


