import { Component, OnInit } from '@angular/core';
import { FiltersService } from '../../services/filters.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { BasketService } from '../../services/basket.service';
import { Country } from '../../models/country';
import { Brand } from '../../models/brand';
import { Region } from '../../models/region';
import { Color } from '../../models/color';
import { Intensity } from '../../models/intensity';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { Filters } from '../../models/filters';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  countries: Country[];
  brands: Brand[];
  colors: Color[];
  regions: Region[];
  intensities: Intensity[];
  subcategories: any;
  prices: any[];
  categoryNameID: any;
  tasteprofiles: Tasteprofile[];
  topProducts: any[];
  public filters: Filters = new Filters;
  dropdownContent: boolean = false;

  public links: any = [
    { name: 'Wines', selected: false, route: 'wine/1/12', base: 'wine', border: 'border-cy-green' },
    { name: 'Spirits', selected: false, route: 'spirits/1/12', base: 'spirits', border: 'border-aq-blue' },
    { name: 'Beers', selected: false, route: 'beers/1/12', base: 'beers', border: 'border-drk-yellow' },
    { name: 'Water & Beverages', selected: false, route: 'water/1/12', base: 'water', border: 'border-abr' },
    { name: 'Coffee', selected: false, route: 'coffee/1/12', base: 'coffee', border: 'border-rar-pink' },
    { name: 'Heets', selected: false, route: 'heets/1/12', base: 'Heets', border: 'border-rar-pink' },
    { name: 'Gifts', selected: false, route: 'gifts/1/12', base: 'Heets', border: 'border-rar-pink' },
    { name: 'Deals', selected: false, route: 'deals/1/12', base: 'Deals', border: 'border-rar-pink' }
    // { name: 'Best Sellers', selected: false, route: 'bestsellers', base: '', border: 'border-rar-pink' }
  ]

  selectedCategory: string = "";
  public searchForm: FormGroup;

  constructor(
    private filtersService: FiltersService,
    public authenticationService: AuthenticationService,
    public basketService: BasketService,
    private formBuilder: FormBuilder,
    private router: Router,
    public globals: Globals

  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      keyword: ['']
    });
  }

  navigateTo(object) {
    this.links.forEach(function (p: any) {
      p.selected = false;
    });

    // object.selected = true;
    this.router.navigateByUrl(object.route);


  }

  resetFilters() {
    this.countries = [];
    this.brands = [];
    this.colors = [];
    this.regions = [];
    this.intensities = [];
    this.subcategories = [];
    this.prices = [];
    this.tasteprofiles = [];
    this.categoryNameID = null;
    this.topProducts = [];
  }

  array(n: string): any[] {
    let item = parseInt(n);
    return Array(item);
  }

  setFilter(type, filter, id?) {
    this.dropdownContent = false;
    id = id == 'undefined' ? '' : new Date().getUTCMilliseconds();
    let state = { filters: filter, types: type };

    this.router.navigateByUrl(this.categoryNameID + '/1/12/'+ id, { state: state }); 
  }

  
  getFiltersByCategoryNameID(categoryNameID) {
    this.dropdownContent = true;
    if (this.selectedCategory != categoryNameID) {
      this.resetFilters();
      this.selectedCategory = categoryNameID;
      this.filtersService.getByNameID(categoryNameID)
        .subscribe(data => {
          this.brands = data.brands;
          this.countries = data.countries;
          this.colors = data.colors;
          this.regions = data.regions;
          this.intensities = data.intensities;
          this.subcategories = data.subcategories;
          this.prices = data.prices;
          this.tasteprofiles = data.tasteprofiles;
          this.categoryNameID = data.category;
          this.topProducts = data.topProducts;
        });
    }
  }
}

