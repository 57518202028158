import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationStart } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Country } from '../../models/country';
import { Brand } from '../../models/brand';
import { Product } from '../../models/product';
import { Color } from '../../models/color';
import { Region } from '../../models/region';
import { Grape } from '../../models/grape';
import { ProductService } from '../../services/product.service';
import { FiltersService } from '../../services/filters.service';
import { Filters } from '../../models/filters';
import { Variant } from '../../models/variant';
import { Intensity } from '../../models/intensity';
import { Globals } from '../../globals';
import { Tasteprofile } from 'src/app/models/tasteprofile';
import { BasketService } from '../../services/basket.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-main',
  templateUrl: './mainnew.component.html',
  styleUrls: ['./mainnew.component.scss']
})

export class MainnewComponent implements OnInit {
  public types: any;
  public fil: any;
  public category: string = null;
  public countries: Country[];
  public subcategories: any = [];
  public brands: Brand[];
  public colors: Color[];
  public products: Product[];
  public filters: Filters = new Filters;
  public regions: Region[];
  public intensities: Intensity[];
  public grapes: Grape[];
  public tasteprofiles: Tasteprofile[];
  public prices: any[];
  public variants: Variant[];
  public currentOrder: string = "Best Seller";
  public routeID: string = null;
  public selectPageForm: FormGroup;
  public sideFilter: boolean = false;

  page: any = "";
  amountPages: any = false;
  currentPage: any = null;
  amountPerPage: any = false;
  amountResults: any = false;
  amountPagingBtns: number = 4;

  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private productService: ProductService,
    private filtersService: FiltersService,
    public basketService: BasketService,
    public globals: Globals,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {


    this.selectPageForm = this.formBuilder.group({
      pageNumber: []
    });


    this.activatedRoute.params.subscribe(routeParams => {
      window.scrollTo(0, 0);
      if (routeParams.categoryNameID && routeParams.page && routeParams.amount) {
        this.page = routeParams.categoryNameID;
        var pagingData: any = { amount: routeParams.amount, page: routeParams.page }
        this.currentPage = routeParams.page;
        this.amountPerPage = routeParams.amount;
        var link = routeParams.categoryNameID + '/1/12';

        if (routeParams.id) {
          this.routeID = routeParams.id;
          link += '/' + this.routeID;
        }

        this.globals.currentPage = link;
        this.category = routeParams.categoryNameID;
        var hasState = false;

        if (window.history.state && window.history.state.filters) {
          this.globals.initProductFilter();
          hasState = true;
        }

        var productfilter = this.globals.productFilter;

        if (productfilter.category != this.category) {
          this.globals.initProductFilter();
          productfilter = this.globals.productFilter;
        }

        var stateFilter = window.history.state.filters;

        if (hasState) {
          stateFilter.type = window.history.state.types;
          stateFilter.active = true;
          productfilter.activeFilters.push(stateFilter);
        }

        this.types = null;

        switch (productfilter.order) {
          case 'nameA':
            this.currentOrder = "Name A - Z";
            break;
          case 'nameZ':
            this.currentOrder = "Name Z - A";
            break;
          case 'priceA':
            this.currentOrder = "Price Ascending";
            break;
          case 'priceD':
            this.currentOrder = "Price Descending";
            break;
          case 'deals':
            this.currentOrder = "Deals";
            break;
          default:
            this.currentOrder = "Best Seller";
            break;
        }



        productfilter.currentcurrency = this.basketService.getCurrency();
        productfilter.category = this.category;
        productfilter.pagingdata = pagingData;

        this.getFiltersByCategoryNameID(this.category, productfilter, hasState, stateFilter);

      }

    });

    this.globals.refreshFilterAction = function (route, country, productService) {
      location.reload();
    };
  }

  setOrder(order, name) {
    this.currentOrder = name;
    var productFilter = this.globals.productFilter;
    productFilter.order = order;
    this.globals.productFilter = productFilter;
    if (this.currentPage == '1') {
      this.productService.filter(productFilter)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          this.filters.lastroute = this.page;
        });
    } else {
      var link = this.category + '/1/12';

      if (this.routeID) {
        link += '/' + this.routeID;
      }
      this.router.navigate([link]);
    }
  }

  toggleFilter() {
    this.globals._sideFilter = !this.globals._sideFilter;
    this.globals._backDrop = !this.globals._backDrop;
  }

  setValue(data): void {
    this.selectPageForm.setValue({
      pageNumber: data
    });
  }

  removeFilters() {

    var productfilter = this.globals.productFilter;
    productfilter.brands = [];
    productfilter.subcategories = [];
    productfilter.regions = [];
    productfilter.countries = [];
    productfilter.tasteprofiles = [];
    productfilter.prices = [];
    productfilter.grapes = [];
    productfilter.intensities = [];
    productfilter.activeFilters = [];
    productfilter.variants = [];


    this.globals.productFilter = productfilter;

    var link = this.category + '/1/12/' + new Date().getUTCMilliseconds();

    // if (this.routeID) {
    //   link += '/' + this.routeID;
    // }

    this.router.navigate([link]);

  }

  uncheckFilter(productFilter, filter) {
    var self = this;
    productFilter.activeFilters.forEach(function (f: any) {
      switch (f.type) {
        case 'subcategories':
          self.subcategories.forEach(function (f: any) {
            if (filter.subcategoryID === f.subcategoryID) {
              f.active = false;
            }
          });
          break;
        case 'grapes':
          self.grapes.forEach(function (g: any) {
            if (filter.grapeID === g.grapeID) {
              g.active = false;
            }
          });
          break;
        case 'tasteprofiles':
          self.tasteprofiles.forEach(function (t: any) {
            if (filter.tasteprofileID === t.tasteprofileID) {
              t.active = false;
            }
          });
          break;
        case 'countries':
          self.countries.forEach(function (c: any) {
            if (filter.countryID === c.countryID) {
              c.active = false;
            }
          });
          break;
        case 'prices':
          self.prices.forEach(function (p: any) {
            if (filter.priceID === p.priceID) {
              p.active = false;
            }
          });
        case 'brands':
          self.brands.forEach(function (b: any) {
            if (filter.brandID === b.brandID) {
              b.active = false;
            }
          });
          break;
        case 'tasteprofiles':
          self.tasteprofiles.forEach(function (t: any) {
            if (filter.tasteprofileID === t.tasteprofileID) {
              t.active = false;
            }
          });
          break;
        case 'intensities':
          self.intensities.forEach(function (i: any) {
            if (filter.intensityID === i.intensityID) {
              i.active = false;
            }
          });
          break;
        case 'variants':
          self.variants.forEach(function (v: any) {
            if (filter.variantID === v.variantID) {
              v.active = false;
            }
          });
          break;
      }
    });
  }

  checkFilters(productFilter, hasState, filter) {
    var self = this;

    productFilter.activeFilters.forEach(function (pf: any) {
      switch (pf.type) {
        case 'subcategories':
          self.subcategories.forEach(function (f: any) {
            if (pf.subcategoryID === f.subcategoryID) {
              f.active = true;
            }
          });
          if (hasState) {
            productFilter.subcategories.push(filter.subcategoryID);
          }
          break;
        case 'grapes':
          self.grapes.forEach(function (g: any) {
            if (pf.grapeID === g.grapeID) {
              g.active = true;
            }
          });
          if (hasState) {
            productFilter.grapes.push(filter.grapeID);
          }
          break;
        case 'tasteprofiles':
          self.tasteprofiles.forEach(function (t: any) {
            if (pf.tasteprofileID === t.tasteprofileID) {
              t.active = true;
            }
          });
          if (hasState) {
            productFilter.tasteprofiles.push(filter.tasteprofileID);
          }
          break;
        case 'countries':
          self.countries.forEach(function (c: any) {
            if (pf.countryID === c.countryID) {
              c.active = true;
            }
          });
          if (hasState) {
            productFilter.countries.push(filter.countryID);
          }
          break;
        case 'prices':
          self.prices.forEach(function (p: any) {
            if (pf.priceID === p.priceID) {
              p.active = true;
            }
          });
          if (hasState) {
            productFilter.prices.push(filter.data);
          }
          break;
        case 'brands':
          self.brands.forEach(function (b: any) {
            if (pf.brandID === b.brandID) {
              b.active = true;
            }
          });
          productFilter.brands.push(filter.brandID);
          break;
        case 'intensities':
          self.intensities.forEach(function (i: any) {
            if (pf.intensityID === i.intensityID) {
              i.active = true;
            }
          });
          productFilter.intensities.push(filter.intensityID);
          break;
        case 'variants':
          self.variants.forEach(function (v: any) {
            if (pf.variantsID === v.variantID) {
              v.active = true;
            }
          });
          productFilter.variants.push(filter.variantID);
          break;
      }
    });

    if (productFilter) {
      this.productService.filter(productFilter)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          this.filters.lastroute = this.page;
        });
    }

    this.globals.productFilter = productFilter;
  }

  checkFilter(type, filter, productFilter) {
    var self = this;
    switch (type) {
      case 'subcategories':
        this.subcategories.forEach(function (f: any) {
          if (filter.subcategoryID === f.subcategoryID) {
            f.active = true;
          }
        });
        productFilter.subcategories.push(filter.subcategoryID);
        break;
      case 'brands':
        this.brands.forEach(function (b: any) {
          if (filter.brandID === b.brandID) {
            b.active = true;
          }
        });
        productFilter.brands.push(filter.brandID);
        break;
      case 'regions':
        this.regions.forEach(function (r: any) {
          if (filter.regionID === r.regionID) {
            r.active = true;
          }
        });
        self.filters.regions.push(filter.regionID);
        break;
      case 'countries':
        this.countries.forEach(function (c: any) {
          if (filter.countryID === c.countryID) {
            c.active = true;
          }
        });
        productFilter.countries.push(filter.countryID);
        break;
      case 'grapes':
        this.grapes.forEach(function (g: any) {
          if (filter.grapeID === g.grapeID) {
            g.active = true;
          }
        });
        productFilter.grapes.push(filter.grapeID);
        break;
      case 'tasteprofiles':
        this.tasteprofiles.forEach(function (t: any) {
          if (filter.tasteprofileID === t.tasteprofileID) {
            t.active = true;
          }
        });
        productFilter.tasteprofiles.push(filter.tasteprofileID);
        break;
      case 'intensities':
        this.intensities.forEach(function (i: any) {
          if (filter.intensityID === i.intensityID) {
            i.active = true;
          }
        });
        productFilter.intensities.push(filter.intensityID);
        break;
      case 'prices':
        self.prices.forEach(function (p: any) {
          if (filter.priceID === p.priceID) {
            p.active = true;
          }
        });
    }

    productFilter.activeFilters.push(filter);
  }


  setFilter(type: any, filter: any) {

    var productFilter = this.globals.productFilter;

    if (!productFilter[type]) {
      productFilter[type] = [];
    }

    filter.type = type;

    switch (type) {
      case 'brands':
        if (filter.active) {
          this.removeItem(type, filter.brandID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'colors':
        if (filter.active) {
          this.removeItem(type, filter.colorID, productFilter, filter);
        } else {
          productFilter.colors.push(filter.colorID);
        }
        break;
      case 'countries':
        if (filter.active) {
          this.removeItem(type, filter.countryID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'regions':
        if (filter.active) {
          this.removeItem(type, filter.regionID, productFilter, filter);
        } else {
          productFilter.regions.push(filter.regionID);
        }
        break;
      case 'intensities':
        if (filter.active) {
          this.removeItem(type, filter.intensityID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'subcategories':
        if (filter.active) {
          this.removeItem(type, filter.subcategoryID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'tasteprofiles':
        if (filter.active) {
          this.removeItem(type, filter.tasteprofileID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'grapes':
        if (filter.active) {
          this.removeItem(type, filter.grapeID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }
        break;
      case 'prices':
        let priceRange = [];
        let currentRange = null;
        const keys = Object.keys(this.prices);

        for (var i = 0; i < keys.length; i++) {
          let item = this.prices[keys[i]];
          let price = item.data;

          let isActive = item.priceID === filter.priceID ? !item.active : item.active;

          if (isActive) {
            if (currentRange) {
              if (currentRange[1] === price[0]) {
                currentRange[1] = price[1];
                continue;
              }
            }

            currentRange = [price[0], price[1]];
            priceRange.push(currentRange);


          }
        }

        if (filter.active) {
          this.removeItem(type, filter.grapeID, productFilter, filter);
        } else {
          this.checkFilter(type, filter, productFilter);
        }

        productFilter.prices = priceRange;
        break;
    }


    productFilter.currentcurrency = this.basketService.getCurrency();

    if (this.currentPage == '1') {

      this.productService.filter(productFilter)
        .subscribe(data => {
          this.products = data.products;
          this.amountPages = data.amountPages;
          this.amountResults = data.amountResults;
          productFilter.lastroute = this.page;
        });
    } else {
      var link = this.category + '/1/12';

      if (this.routeID) {
        link += '/' + this.routeID;
      }

      this.router.navigate([link]);
    }

    this.globals.productFilter = productFilter;

  }

  counter(i: number) {
    let x: any = false;
    if (this.amountPages > this.amountPagingBtns) {
      x = new Array(this.amountPagingBtns);
    } else {
      x = new Array(i);
    }
    return x;
  }

  removeItem(type, item, productFilter, filter) {
    this.uncheckFilter(productFilter, filter);

    productFilter[type].forEach(function (bp, key) {
      if (bp == item) {
        productFilter[type].splice(key, 1);
      }
    });

    productFilter.activeFilters.forEach(function (f, key) {
      if (f.name == filter.name) {
        productFilter.activeFilters.splice(key, 1);
      }
    });
  }

  getFiltersByCategoryNameID(categoryNameID, productfilter, hasState, filter) {
    this.filtersService.getByNameID(categoryNameID)
      .subscribe(data => {
        this.brands = data.brands;
        this.countries = data.countries;
        this.colors = data.colors;
        this.regions = data.regions;
        this.intensities = data.intensities;
        this.subcategories = data.subcategories;
        this.tasteprofiles = data.tasteprofiles;
        this.grapes = data.grapes;
        this.prices = data.prices;
        this.variants = data.variants;

        this.checkFilters(productfilter, hasState, filter);
      });

  }

  getProductsByCategoryNameID(categoryNameID, pagingData: Object) {
    this.productService.getProductsByCategoryNameID(categoryNameID, pagingData)
      .subscribe(data => {
        this.products = data.products;
        this.amountPages = data.amountPages;
        this.amountResults = data.amountResults;
      });
  }

  getSubCategoriesByCategoryNameID(data) {
    this.categoryService.getSubcategoryByCategoryNameID(data)
      .subscribe(data => {
        // this.subCategories = data;
      });
  }

  previousPage(page, amountPages, amountPerPage) {
    window.scrollTo(0, 0);
    var link = '/' + page + '/' + amountPages + '/' + amountPerPage;
    if (this.routeID) {
      link += '/' + this.routeID;
    }
    this.setValue('');

    this.router.navigate([link]);
  }

  paginationDropDownRage(n: number, startFrom: number): number[] {
    return [...Array(n).keys()].map(i => i + startFrom);
  }

  navigateTo(page, amountPerPage) {
    var currentPage = this.selectPageForm.value.pageNumber;
    if (currentPage) {
      var link = '/' + page + '/' + currentPage + '/' + amountPerPage;
      if (this.routeID) {
        link += '/' + this.routeID;
      }
      this.router.navigate([link]);
    } else {
      this.setValue(this.currentPage);
    }
  }

  nextPage(page, amountPages, amountPerPage) {
    window.scrollTo(0, 0);
    var link = '/' + page + '/' + amountPages + '/' + amountPerPage;
    if (this.routeID) {
      link += '/' + this.routeID;
    }
    this.router.navigate([link]);
  }
}
