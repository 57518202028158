<form [formGroup]="addHomeItemForm">
    <div class="wrapper main overflow">
        <div class="category-title">Insert Home Item</div>
        <div class="row-">
            <div class="col-right">
                <div class="row">
                    <div style="text-align: center;" class="col col-img">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div class="col-left">
                <div style="margin-bottom: 40px;">
                    <span>Home Item Name</span><br />
                    <input formControlName="name" type="text" class="form-control" id="name" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Add Title?</span><br />
                    <select formControlName="addTitle" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div *ngIf="addHomeItemForm.value.addTitle === '1'" style="margin-bottom: 40px;">
                    <span>Title</span><br />
                    <input formControlName="title" type="text" class="form-control" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Add Action Button?</span><br />
                    <select formControlName="addActionButton" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div *ngIf="addHomeItemForm.value.addActionButton === '1'" style="margin-bottom: 40px;">
                    <span>Action Button Name</span><br />
                    <select formControlName="buttonNameID" class="form-control">
                        <option [value]="'0'">None</option>
                        <option [value]="btn.buttonNameID" *ngFor="let btn of buttonnames">{{btn.buttonName}}</option>
                    </select>
                </div> 
                <div *ngIf="addHomeItemForm.value.addActionButton === '1'" style="margin-bottom: 40px;">
                    <span>Button Action</span><br />
                    <input formControlName="redirectUrl" type="text" class="form-control" id="redirectUrl"
                        placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Add Description?</span><br />
                    <select formControlName="addDescription" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div *ngIf="addHomeItemForm.value.addDescription === '1'" style="margin-bottom: 40px;">
                    <span>Description</span><br />
                    <textarea formControlName="description" class="form-control"></textarea>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Publish Curacao</span><br />
                    <select formControlName="publishCur" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Publish Bonaire</span><br />
                    <select formControlName="publishBon" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Active</span><br />
                    <select formControlName="active" class="form-control" id="active">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </div>
                <div style="margin-bottom: 40px;">
                    <span>PIN</span><br />
                    <input formControlName="pin" type="password" class="form-control" id="pin" placeholder="">
                </div>
                <div style="height: 30px;">
                    <div *ngIf="updated">
                        Succesfully Created
                    </div>
                    <div *ngIf="pin === false">
                        Invalid pin
                    </div>
                </div>
                <div style="padding:0px 0 50px 0px ;">
                    <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                        <span *ngIf="!loading">Insert Home Item</span>
                        <span *ngIf="loading">
                            Inserting Home Item&nbsp;&nbsp;<img height="10"
                                src="../../../assets/spinners/three-dots.svg" />
                        </span>
                    </div>
                </div>
                <div style="height: 50px;">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</form>