import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tasteprofile} from '../models/tasteprofile';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TasteprofileService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }


  getAll(): Observable<Tasteprofile[]> {
    return this.http.post<Tasteprofile[]>(`${this.globals.serverlink}getTasteprofiles.php`, {});
  }
}
