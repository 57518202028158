import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CustomerproductService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getCustomerProducts() {
    return this.http.post<Product>(`${this.globals.serverlink}getCustomerDeals.php`, {});
  }
}
