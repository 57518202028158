<div class="container">
    <div class="row">
        <div *ngFor="let product of products" class="col">
            <div class="product-wrapper">
                <div class="product-header mobile-header">
                    <div class="price-area disable-on-mobile">
                        <div [ngClass]="{'product-price-disable': product.deal === '1' }" class="disabled-line">
                            &nbsp;
                        </div>
                        <span class="" style="font-size: 14px;">
                            <span *ngIf="product.buttonActionID != '4' && product.productID != '1967' && product.buttonNameID !== '1'">{{basketService.getCurrency()}}&nbsp;</span>
                        </span>
                        <span *ngIf="product.buttonActionID != '4'  && product.productID != '1967' && product.buttonNameID !== '1'"
                            class="product-price">{{productService.getProductPrice(product)}} </span>
                        <div *ngIf="product.deal == '1'" class="product-price-deal">
                            {{productService.getDealPrice(product)}}
                        </div>
                    </div>
                    <div *ngIf="product.color && product.color != '0'" [ngStyle]="{'background-color': product.color}"
                        class="profiling-area">
                        &nbsp;
                    </div>
                </div>
                <div routerLink="/product/{{product.productID}}" class="product-image-area">
                    <img src="../../../assets/products/{{product.code}}.jpg" />
                </div>
                <div class="product-header">
                    <div class="price-area show-on-mobile">
                        <div [ngClass]="{'product-price-disable': product.deal === '1'}" class="disabled-line">
                            &nbsp;
                        </div>
                        <span class="product-currency">
                            {{basketService.getCurrency()}}
                        </span>
                        <span class="product-price">{{productService.getProductPrice(product)}} </span>
                        <span *ngIf="product.deal == '1'" class="product-price-deal">
                            {{productService.getDealPrice(product)}}
                        </span>
                    </div>
                </div>
                <div class="intensity-container" *ngIf="product.intensity && product.intensity != '0'">
                    <div *ngFor="let intens of array(product.intensity)" class="intensity-block">
                        &nbsp;
                    </div>
                </div>
                <div [innerHTML]="product.name" class="product-name-area">
                    &nbsp;
                </div>
                <div *ngIf="product.buttonActionID != '2' && product.buttonNameID !== '11' && product.buttonActionID != '4' && product.buttonActionID != 5"
                    class="product-order-area">
                    <div (click)="add(product, 1)" class="product-order-button">
                        {{product.buttonName}}&nbsp;&nbsp;<span *ngIf="product.typeID != '6'"><i
                                class="fal fa-plus"></i></span>
                    </div>
                    <div *ngIf="product.categoryID == '2' && product.buttonNameID !== '11'" (click)="add(product, 6)"
                        class="product-order-button disable-on-mobile">Case (6)
                    </div>
                </div>
                <div *ngIf="product.buttonActionID == '4' && product.buttonNameID !== '11'" class="product-order-area">
                    <div routerLink="/nespressoprofessional" class="product-order-button">
                        {{product.buttonName}}
                    </div>
                </div>
                <div *ngIf="product.buttonActionID == '5' && product.buttonNameID !== '11'" class="product-order-area">
                    <div routerLink="/customizegift" class="product-order-button">
                        {{product.buttonName}}
                    </div>
                </div>
                <div *ngIf="product.buttonActionID == '2' && product.buttonNameID !== '11'" class="product-order-area">
                    <ng-template #popContent>
                        <div style="overflow: hidden; width: 200px;">
                            <div *ngFor="let amount of getGridAmount(product.gridIteration)"
                                style="float: left; padding: 10px 0; cursor: pointer; text-align: center; width: 50px;">
                                <span class="amount-prod-box" (click)="add(product, amount.name)">{{amount.name}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <button class="product-order-button" [ngbPopover]="popContent">
                        {{product.buttonName}} &nbsp;&nbsp;<i class="fal fa-plus"></i>
                    </button>
                </div>
            </div>

            <!-- <div routerLink="/product/{{product.productID}}" class="product-logo">
                <div [ngClass]="{'product-price-disable': product.deal === '1'}" class="product-price"><span
                        style="font-size: 14px;">{{basketService.getCurrency()}}
                    </span>{{productService.getProductPrice(product)}}
                </div>
                <div *ngIf="product.deal == '1'" class="product-price-deal">{{productService.getDealPrice(product)}}
                </div>
                <div *ngIf="product.color && product.color != '0'" [ngStyle]="{'background-color': product.color}"
                    class="product-color">&nbsp;</div>
                <div class="placeholder"><img src="../../../assets/products/{{product.code}}.jpg" height="200"
                        width="200" /></div>
            </div>
            <div class="intensity-container" *ngIf="product.intensity && product.intensity != '0'">
                <div *ngFor="let intens of array(product.intensity)" class="intensity-block">
                    &nbsp;
                </div>
            </div>
            <div class="product-name">
                {{product.name}}
            </div>
            <div class="product-add-wrapper">
                <div *ngIf="product.categoryID != '6'" class="flex-container">
                    <div (click)="add(product, 1)">Add {{product.typeName}}&nbsp;&nbsp;<i class="fal fa-plus"></i></div>
                    <div *ngIf="product.categoryID == '2'" (click)="add(product, 6)" class="text mobile">Case
                        (6)&nbsp;&nbsp;<i class="fal fa-plus"></i></div>
                </div>
                <div style="text-align: center;" *ngIf="product.categoryID == '6'">
                    <ng-template #popContent>
                        <div style="overflow: hidden; width: 200px;">
                            <div *ngFor="let amount of amountCoffees"
                                style="float: left; padding: 10px 0; cursor: pointer; text-align: center; width: 50px;">
                                <span class="amount-prod-box" (click)="add(product, amount.name)">{{amount.name}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <button style="border: 0;" type="button" class="product-add-btn" [ngbPopover]="popContent">
                        Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;<i class="fal fa-plus"></i>
                    </button>
                </div>
            </div> -->
        </div>
        <div *ngIf="products && products.length == 0">
            Sorry. We did not find any results from your search.
        </div>
    </div>
</div>