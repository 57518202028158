<form [formGroup]="brandForm">
    <div  class="wrapper main overflow">
        <div class="category-title">Add Brand</div>
        <div class="row-">
            <div class="col-right">
                <div class="row">
                    <div style="text-align: center;" class="col col-img">
                       &nbsp;
                    </div>
                </div>
            </div>
            <div class="col-left">
                <div style="margin-bottom: 40px;">
                    <span>Brand Name</span><br />
                    <input formControlName="name" type="text" class="form-control" id="name" placeholder="">
                </div>
                <div style="margin-bottom: 40px;">
                    <span>Position</span><br />
                    <input formControlName="position" type="text" class="form-control" id="code" placeholder="">
                </div>   
                <div style="margin-bottom: 40px;">
                    <span>PIN</span><br />
                    <input formControlName="pin" type="password" class="form-control" id="pin" placeholder="">
                </div>
                <div style="height: 30px;">
                    <div *ngIf="updated">
                        Succesfully Created
                    </div>
                    <div *ngIf="pin === false">
                        Invalid pin
                    </div>
                </div>
                <div style="padding:0px 0 50px 0px ;">
                    <div [ngClass]="{'submit-btn-on' : !loading}" class="submit-btn" (click)="submit()">
                        <span *ngIf="!loading">Add Brand</span>
                        <span *ngIf="loading">
                            Inserting Product&nbsp;&nbsp;<img height="10"
                                src="../../../assets/spinners/three-dots.svg" />
                        </span>
                    </div>
                </div> 
                <div style="height: 50px;">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</form>