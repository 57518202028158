import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Type } from '../models/type';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getAll(): Observable<Type[]> {
    return this.http.post<Type[]>(`${this.globals.serverlink}getTypes.php`, {});
  }
}
