import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Color } from '../models/color';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor(
    private http: HttpClient,
    private globals: Globals) { }

  getColorsByCategoryNameID(categoryNameID:number): Observable<Color[]> {
    return this.http.post<Color[]>(`${this.globals.serverlink}getColorsByCategoryNameID.php`, {categoryNameID});
  }

  getAll(): Observable<Color[]> {
    return this.http.post<Color[]>(`${this.globals.serverlink}getColors.php`, {});
  }

}
